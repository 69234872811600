import PlaceIcon from "@mui/icons-material/Place";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../../components/forms/payment-method-checkbox.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import InsufficientCreditModal from "../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { getProfileDetail } from "../../../../services/profile/profile-slice.service";
import {
  createSportBooking,
  previewSportBooking,
  sportBookingSelector,
} from "../../../../services/sport/booking/sport-booking-slice.service";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required().label("Payment Method"),
  promoCode: Yup.string().nullable().label("Promo Code"),
});

function SportPreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { previewSportBookingObj } = useSelector(sportBookingSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [redeemPromo, setRedeemPromo] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const previewBooking = (values, promoRedeem, itemIndex) => {
    if (promoRedeem) {
      setRedeemPromo(true);
      setIsLoading(true);
    }

    dispatch(previewSportBooking(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
      }

      if (meta.requestStatus === "rejected") {
        setIsLoading(false);
        const validPayload = { ...values };
        validPayload.slots[itemIndex].promoCode = "";
        formRef.current?.setFieldValue(`slots[${itemIndex}].promoCode`, "");
        previewBooking(validPayload, true, null);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    dispatch(createSportBooking(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.SPORT_BOOKING_PAYMENT_SUCCESS, { state: { ...payload.data } });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (values.paymentMethod === "credits" && !previewSportBookingObj.data.creditPurchasable) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    previewBooking(location.state, false, null);
  }, []);

  return (
    <Box>
      {previewSportBookingObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Sport Booking</TitleTypography>
        <Spacer size="l" />
        {previewSportBookingObj.status === "succeeded" || redeemPromo ? (
          <Form
            innerRef={formRef}
            initialValues={{
              paymentMethod: "credits",
              duration: location.state.duration,
              date: location.state.date,
              time: location.state.time,
              courtIds: location.state.courtIds,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid container columnSpacing={2} alignContent="center">
                        <Grid
                          item
                          xs={4}
                          alignSelf="center"
                          paddingY="5px"
                          alignItems="center"
                          display="flex"
                        >
                          <img
                            src={previewSportBookingObj.data.business.images[0].imagePath}
                            alt="businessImage"
                            width="100%"
                            height={isMobile ? 100 : 140}
                            style={{
                              borderRadius: `${theme.shape.borderRadius[2]}px`,
                              objectFit: "cover",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          display="grid"
                          flexDirection="column"
                          alignContent="space-between"
                          marginY="10px"
                        >
                          <Grid item>
                            <Grid item>
                              <Typography
                                fontWeight="bold"
                                className="businessTitle"
                                maxWidth={width < 450 ? "180px" : "none"}
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {previewSportBookingObj.data.business.name}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              <Typography
                                sx={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {previewSportBookingObj.data.business.location.address}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              <PlaceIcon sx={{ color: theme.palette.colors.brand.primary }} />
                              <Spacer position="left" />
                              <Typography>
                                {previewSportBookingObj.data.business.location.city}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Spacer size="m" />
                      <Typography fontWeight="bold">Slot</Typography>
                      <Spacer size="s" />
                      <Grid item container justifyContent="space-between">
                        <Typography>Date</Typography>
                        <Typography>{previewSportBookingObj.data.slot.date}</Typography>
                      </Grid>
                      <Spacer size="s" />
                      <Grid item container justifyContent="space-between">
                        <Typography>Time</Typography>
                        <Typography>{previewSportBookingObj.data.slot.time}</Typography>
                      </Grid>
                      <Spacer size="m" />
                      <Typography fontWeight="bold">Courts</Typography>
                      <Spacer size="s" />
                      {previewSportBookingObj.data?.courts.map((item) => (
                        <>
                          <Grid item container justifyContent="space-between">
                            <Typography>{item.court.label}</Typography>
                            <Typography>RM {item.subtotal}</Typography>
                          </Grid>
                          <Spacer size="s" />
                        </>
                      ))}
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>Payment Method</SectionTypography>
                      <Spacer size="m" />
                      <PaymentMethodCheckbox />
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 3 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="220px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>
                            RM {previewSportBookingObj.data?.paymentSummary.subtotal}
                          </BodyTypography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <HorizontalDivider spacerSize="l" />
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>
                            RM {previewSportBookingObj.data?.paymentSummary.total}
                          </SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <FormSubmitButton width="100%" borderRadius={3}>
                            Confirm
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default SportPreviewSummaryScreen;
