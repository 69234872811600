import AddIcon from "@mui/icons-material/Add";
import PlaceIcon from "@mui/icons-material/Place";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import { useSelector } from "react-redux";
import FormCalendarPickerDate from "../../../components/forms/form-calendar-picker-date.component";
import FormPickerTime from "../../../components/forms/form-picker-time.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { sportBookingSelector } from "../../../services/sport/booking/sport-booking-slice.service";
import { sportSelector } from "../../../services/sport/sport-slice.service";
import CategoryFilter from "../../explore/components/category-filter.component";
import CustomButton from "../../../components/button/custom-button.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "80%",
  outline: "none",
  overflow: "auto",
}));

function Filters({ getSportLiveAvailability }) {
  const theme = useTheme();
  const { getBusinessObj } = useSelector(sportSelector);
  const { getLiveAvailabilityObj } = useSelector(sportBookingSelector);
  const { values, setFieldValue } = useFormikContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setShowModal] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getSportLiveAvailability(values.q, dayjs(new Date(values.date)).format("YYYY-MM-DD"));
  }, [values.date, values.q]);

  return (
    <Grid container rowSpacing={2} sx={{ position: "relative" }}>
      {getLiveAvailabilityObj.status === "succeeded" && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <ModalBox isMobile={isMobile}>
            <Grid item xs={12} sx={{ padding: "15px" }}>
              <CustomButton
                width="auto"
                variant="text"
                fontSize={theme.fonts.fontSizes.size15}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {"< Back"}
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign="center" variant="h6" fontWeight="bold">
                Live Availability
              </Typography>
            </Grid>
            <TableContainer component={Paper} sx={{}}>
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    {getLiveAvailabilityObj.data.tableHead.map((head) => (
                      <TableCell
                        key={head}
                        align="center"
                        sx={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        {head}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {getLiveAvailabilityObj.data.tableData.map((row) => (
                    <TableRow key={row[0]}>
                      {row.map((cell, index) => (
                        <TableCell key={uuidv4()} align="center">
                          {index === 0 ? (
                            cell
                          ) : (
                            <Box
                              sx={{
                                width: "60px",
                                height: "60px",
                                border: "1.5px solid",
                                borderColor: theme.palette.colors.brand.primary,
                                borderRadius: "10px",
                                backgroundColor:
                                  cell === "booked"
                                    ? theme.palette.colors.brand.primary
                                    : undefined,
                              }}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ModalBox>
        </Modal>
      )}

      <Grid item xs={12}>
        <Typography>Type of Sport</Typography>
        <Typography color="#666" variant="subtitle2">
          Please select the sport you would like to book
        </Typography>
        <CategoryFilter searchFor="sport" />
      </Grid>
      <Grid item xs={12}>
        {getBusinessObj.status === "succeeded" ? (
          <Grid
            item
            sx={{
              backgroundColor: theme.palette.colors.bg.primary,
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              objectFit: "cover",
            }}
          >
            <PaddedView multiples={2}>
              <Grid container columnSpacing={2} alignContent="center">
                <Grid
                  item
                  xs={4}
                  alignSelf="center"
                  paddingY="5px"
                  alignItems="center"
                  display="flex"
                >
                  <img
                    src={getBusinessObj.data.images[0].imagePath}
                    alt="businessImage"
                    width="100%"
                    height={isMobile ? 100 : 130}
                    style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  display="grid"
                  flexDirection="column"
                  alignContent="space-between"
                  marginY="10px"
                >
                  <Grid item>
                    <Grid item>
                      <Typography
                        fontWeight="bold"
                        className="businessTitle"
                        maxWidth={width < 450 ? "180px" : "none"}
                        sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {getBusinessObj.data.name}
                      </Typography>
                    </Grid>
                    <Spacer />
                    <Grid item display="flex" alignContent="center">
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {getBusinessObj.data.location.address}
                      </Typography>
                    </Grid>
                    <Spacer />
                    <Grid item display="flex" alignContent="center">
                      <PlaceIcon sx={{ color: theme.palette.colors.brand.primary }} />
                      <Spacer position="left" />
                      <Typography>{getBusinessObj.data.location.city}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PaddedView>
          </Grid>
        ) : (
          <CustomSkeleton width="100%" height="140px" />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography>Date</Typography>
        <FormCalendarPickerDate name="date" disablePast submitOnChange={!isMobile} />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="right">
        <Button
          onClick={() => setShowModal(true)}
          variant="contained"
          sx={{ textTransform: "none", borderRadius: "10px" }}
          disabled={getLiveAvailabilityObj.status === "pending"}
        >
          See Live Availability
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "grid",
          "& .MuiTextField-root": {
            justifySelf: "center",
          },
          "& .MuiInputBase-root": {
            borderRadius: "10px",
          },
        }}
      >
        <Typography>Time</Typography>
        <FormPickerTime name="time" />
      </Grid>
      <Grid item xs={12}>
        <Typography>Duration</Typography>
        <Grid container alignItems="center" columnSpacing={2} justifyContent="center">
          <Grid item>
            <IconButton
              color="primary"
              sx={{ border: "1.5px solid" }}
              onClick={() => {
                if (values.duration - 0.5 >= 1) {
                  setFieldValue("duration", values.duration - 0.5);
                }
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: "1.5px solid",
                padding: "15px",
                borderRadius: "10px",
                borderColor: theme.palette.colors.brand.primary,
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                {values.duration} Hours
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              sx={{ border: "1.5px solid" }}
              onClick={() => {
                if (values.duration + 0.5 <= 6) {
                  setFieldValue("duration", values.duration + 0.5);
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
        <Spacer />
        <FormSubmitButton isLoading={false} width={300} borderRadius={3}>
          <Typography>Check Availability</Typography>
        </FormSubmitButton>
      </Grid>
    </Grid>
  );
}

export default Filters;

Filters.propTypes = {
  getSportLiveAvailability: PropTypes.func.isRequired,
};
