import { configureStore } from "@reduxjs/toolkit";
import authSliceService from "./auth/auth-slice.service";
import bannerSliceService from "./banner/banner-slice.service";
import bookingSliceService from "./booking/booking-slice.service";
import businessSliceService from "./business/business-slice.service";
import categorySegmentService from "./category-segment/category-segment.service";
import categorySliceService from "./category/category-slice.service";
import creditSliceService from "./credit/credit-slice.service";
import exclusiveReferralSliceService from "./exclusive-referral/exclusive-referral-slice.service";
import eventBookingBillSliceService from "./event/booking/bill/event-booking-bill-slice.service";
import eventBookingSliceService from "./event/booking/event-booking-slice.service";
import sportBookingBillSliceService from "./sport/booking/bill/sport-booking-bill-slice.service";
import sportBookingSliceService from "./sport/booking/sport-booking-slice.service";
import fitnessClassSessionBookingBillSliceService from "./fitness_class/session/booking/bill/fitness-class-session-booking-bill-slice.service";
import fitnessClassSessionBookingSliceService from "./fitness_class/session/booking/fitness-class-session-booking-slice.service";
import fitnessClassSessionSliceServices from "./fitness_class/session/fitness-class-session-slice.services";
import hourlyBookingBillSliceService from "./hourly-booking/bill/hourly-booking-bill-slice.service";
import hourlyBookingSliceService from "./hourly-booking/hourly-booking-slice.service";
import messageSettingSliceService from "./message-setting/message-setting-slice.service";
import packageBillSliceService from "./package/bill/package-bill-slice.service";
import packageHourlyBookingSliceService from "./package/hourly-booking/package-hourly-booking-slice.service";
import packageSliceService from "./package/package-slice.service";
import packagePlanSliceService from "./package/plan/package-plan-slice.service";
import purchasedPackageSliceService from "./package/purchased-package/purchased-package-slice.service";
import packageFitnessSessionBookingSliceService from "./package/session/booking/package-fitness-session-booking-slice.service";
import packageFitnessSessionSliceService from "./package/session/package-fitness-session-slice.service";
import profileSliceService from "./profile/profile-slice.service";
import referralSliceService from "./referral/referral-slice.service";
import reviewSliceService from "./review/review-slice.service";
import searchBusinessSliceService from "./search/business/search-business-slice.service";
import searchEventSliceService from "./search/event/search-event-slice.service";
import searchSportSliceService from "./search/sport/search-sport-slice.service";
import searchFitnessClassSliceService from "./search/fitness-class/search-fitness-class-slice.service";
import searchSliceService from "./search/search-slice.service";
import socialSliceService from "./social/social-slice.service";
import subscriptionBillSliceService from "./subscription/bill/subscription-bill-slice.service";
import subscriptionPlanSliceService from "./subscription/plan/subscription-plan-slice.service";
import purchasedSubscriptionSliceService from "./subscription/purchased/purchased-subscription-slice.service";
import subscriptionSliceService from "./subscription/subscription-slice.service";
import leadSliceService from "./lead/lead-slice.service";
import eventSessionSliceService from "./event/session/event-session-slice.service";
import highlightSliceService from "./highlight/highlight-slice.service";
import accountDeletionSliceService from "./account-deletion/account-deletion-slice.service";
import tokenizedPaymentSliceService from "./tokenized-payment/tokenized-payment-slice.service";
import appointmentSliceService from "./appointment/appointment-slice.service";
import membershipPlanSliceService from "./membership/plan/membership-plan-slice.service";
import membershipBillSliceService from "./membership/bill/membership-bill-slice.service";
import membershipSliceService from "./membership/membership-slice.service";
import sportSliceService from "./sport/sport-slice.service";

const store = configureStore({
  reducer: {
    auth: authSliceService,
    appointment: appointmentSliceService,
    banner: bannerSliceService,
    categorySegment: categorySegmentService,
    messageSetting: messageSettingSliceService,
    booking: bookingSliceService,
    profile: profileSliceService,
    social: socialSliceService,
    referral: referralSliceService,
    credits: creditSliceService,
    category: categorySliceService,
    search: searchSliceService,
    searchBusiness: searchBusinessSliceService,
    searchFitnessClass: searchFitnessClassSliceService,
    searchEvent: searchEventSliceService,
    searchSport: searchSportSliceService,
    sport: sportSliceService,
    business: businessSliceService,
    eventSession: eventSessionSliceService,
    eventBooking: eventBookingSliceService,
    eventBookingBill: eventBookingBillSliceService,
    review: reviewSliceService,
    fitnessClassSession: fitnessClassSessionSliceServices,
    fitnessClassSessionBooking: fitnessClassSessionBookingSliceService,
    fitnessClassSessionBookingBill: fitnessClassSessionBookingBillSliceService,
    package: packageSliceService,
    packagePlan: packagePlanSliceService,
    packageBill: packageBillSliceService,
    packageHourlyBooking: packageHourlyBookingSliceService,
    purchasedPackage: purchasedPackageSliceService,
    packageFitnessSession: packageFitnessSessionSliceService,
    packageFitnessSessionBooking: packageFitnessSessionBookingSliceService,
    subscription: subscriptionSliceService,
    subscriptionPlan: subscriptionPlanSliceService,
    subscriptionBill: subscriptionBillSliceService,
    purchasedSubscription: purchasedSubscriptionSliceService,
    hourlyBooking: hourlyBookingSliceService,
    hourlyBookingBill: hourlyBookingBillSliceService,
    leads: leadSliceService,
    highlight: highlightSliceService,
    accountDeletion: accountDeletionSliceService,
    tokenizedPayment: tokenizedPaymentSliceService,
    exclusiveReferral: exclusiveReferralSliceService,
    membership: membershipSliceService,
    membershipPlan: membershipPlanSliceService,
    membershipBill: membershipBillSliceService,
    sportBooking: sportBookingSliceService,
    sportBookingBill: sportBookingBillSliceService,
  },
});

export default store;
