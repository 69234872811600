import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Avatar, Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircleTick from "../../assets/images/circle_tick";
import CustomButton from "../../components/button/custom-button.component";
import routes from "../../components/navigation/routes";
import HorizontalDivider from "../../components/utils/horizontal-divider.component";
import PaddedView from "../../components/utils/padded-view.component";
import Spacer from "../../components/utils/spacer.component";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

export default function BillPaymentSuccessful() {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const remoteBillId = new URLSearchParams(location.search).get("remoteBillId");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderMessage = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTSUCCESS:
        return "Your top-up is successful.";
      case routes.HOURLYBOOKINGPAYMENTSUCCESS:
      case routes.SPORT_BOOKING_PAYMENT_SUCCESS:
        return "You’ll get an email along with your booking confirmation.";
      case routes.PACKAGE_HOURLY_BOOKING_PAYMENT_SUCCESS:
        return `Successfully booked with ${location.state.package.code}. See you then!`;
      case routes.SUBSCRIPTIONPLANPAYMENTSUCCESS:
        return `You have subscribed to the ${location.state.subscription.plan.title} successfully.
        You can now enjoy unlimited access to our Engage Signature and Mini Gyms
        for ${location.state.subscription.plan.numberOfDays} days.`;
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS:
        if (location.state.package && location.state.package.code) {
          return `Successfully booked with ${location.state.package.code}. See you then!`;
        }
        return "You’ll get an email along with your booking confirmation.";

      case routes.PACKAGEPAYMENTSUCCESS:
        return "You’ll get an email along with your booking confirmation.";
      case routes.EVENTBOOKINGPAYMENTSUCCESS:
        return "You’ll get an email along with your booking confirmation.";
      case routes.APPOINTMENTPAYMENTSUCCESS:
        return (
          <>
            Successful redeemed with{" "}
            <span style={{ fontWeight: "bold", color: theme.palette.colors.brand.primary }}>
              {" "}
              {location.state.paymentTransaction.packageCodeRedemption.code}
            </span>
            .You’ll get an email along with your booking confirmation.
          </>
        );
      case routes.MEMBERSHIP_PLAN_PAYMENT_SUCCESS:
        return (
          <>
            Successful purchased. <br /> You’ll get an email along with your membership
            confirmation.
          </>
        );

      default:
        return false;
    }
  };

  const showNextDay = (startAt, endAt) => {
    if (!startAt || !endAt) {
      return false;
    }
    const startDate = new Date(startAt);
    const endDate = new Date(endAt);

    // Compare the two dates
    if (startDate.getDate() !== endDate.getDate()) {
      return true;
    }

    return false;
  };

  const gymSessionBookingReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Booking Details</TitleTypography>
      <Spacer size="l" />
      <Typography fontSize={theme.fonts.fontSizes.size14}>GYM SESSION</Typography>
      <Spacer size="m" />
      {location.state.bookings.map((item) => (
        <Grid item key={item.id}>
          <Grid container justifyContent="space-between">
            <TitleTypography>{item.business.name}</TitleTypography>
          </Grid>
          <Spacer />
          <Grid item>
            {item.memberPrice && (
              <Grid item display="flex" alignContent="center">
                <Typography fontWeight="bold" color="primary">
                  RM {item.memberPrice} (MEMBER)
                </Typography>
              </Grid>
            )}

            <Grid item display="flex" alignContent="center">
              <Typography fontWeight="bold" color={item.memberPrice ? "grey" : "primary"}>
                RM {item.price} {item.memberPrice && "(NON-MEMBER)"}
              </Typography>
            </Grid>
          </Grid>
          <Spacer />
          <Grid item>
            {item.promoCode && (
              <BodyTypography>
                Promo Code Applied:{" "}
                <span style={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                  {item.promoCode}
                </span>
              </BodyTypography>
            )}

            <BodyTypography>Date: {item.date}</BodyTypography>
            <BodyTypography>
              Time: {item.time} {showNextDay(item.startAt, item.endAt) && "(next day)"}
            </BodyTypography>
            <BodyTypography>
              Access Time: {item.access.start} - {item.access.end}{" "}
              {showNextDay(item.startAt, item.endAt) && "(next day)"}
            </BodyTypography>
          </Grid>
          <Spacer size="l" />
        </Grid>
      ))}
      <HorizontalDivider spacerSize="l" />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>

          {location.state.paymentTransaction.membershipDiscount !== "0.00" && (
            <>
              <Grid item xs={6}>
                <BodyTypography>Membership Discount</BodyTypography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <BodyTypography>
                  -RM {location.state.paymentTransaction.membershipDiscount}
                </BodyTypography>
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <BodyTypography>Voucher Discount</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>-RM {location.state.paymentTransaction.voucherDiscount}</BodyTypography>
          </Grid>

          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );

  const packageGymSessionBookingReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <Grid container justifyContent="space-between">
        <TitleTypography>Booking Details</TitleTypography>
        <Grid item display="flex" alignItems="center">
          <Typography
            fontSize={theme.fonts.fontSizes.size14}
            sx={{
              border: "1px solid",
              borderColor: theme.palette.colors.ui.error,
              color: theme.palette.colors.ui.error,
              paddingX: "10px",
            }}
          >
            NON-REFUNDABLE
          </Typography>
        </Grid>
      </Grid>{" "}
      <Spacer size="l" />
      <Typography fontSize={theme.fonts.fontSizes.size14}>GYM SESSION</Typography>
      <Spacer size="m" />
      {location.state.bookings.map((item) => (
        <Grid item key={item.id}>
          <Grid container justifyContent="space-between">
            <TitleTypography>{item.business.name}</TitleTypography>
            <BodyTypography color="primary" fontWeight="bold">
              {location.state.package.code}
            </BodyTypography>
          </Grid>
          <Spacer />
          <Spacer />
          <Grid item>
            <BodyTypography>Date: {item.date}</BodyTypography>
            <BodyTypography>
              Time: {item.time} {showNextDay(item.startAt, item.endAt) && "(next day)"}
            </BodyTypography>
            <BodyTypography>
              Access Time: {item.access.start} - {item.access.end}{" "}
              {showNextDay(item.startAt, item.endAt) && "(next day)"}
            </BodyTypography>
          </Grid>
          <Spacer size="l" />
        </Grid>
      ))}
    </PaddedView>
  );

  const fitnessClassSessionBookingReceipt = () => {
    if (location.state.package) {
      return (
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid container justifyContent="space-between">
            <TitleTypography>Booking Details</TitleTypography>
            <Grid item display="flex" alignItems="center">
              <Typography
                fontSize={theme.fonts.fontSizes.size14}
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.colors.ui.error,
                  color: theme.palette.colors.ui.error,
                  paddingX: "10px",
                }}
              >
                NON-REFUNDABLE
              </Typography>
            </Grid>
          </Grid>

          <Spacer size="s" />
          <Typography fontSize={theme.fonts.fontSizes.size14}>
            {location.state.class.category.label}
          </Typography>
          <Spacer size="s" />
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <TitleTypography>{location.state.class.name}</TitleTypography>
              <Spacer position="left" />
              <BodyTypography color="primary" fontWeight="bold">
                {location.state.package.code}
              </BodyTypography>
            </Grid>
            <Spacer />
            <Grid item>
              <BodyTypography>
                {location.state.class.session.startTime} (
                {location.state.class.session.durationMins}
                mins) | {location.state.class.session.date}
              </BodyTypography>
              <BodyTypography>{location.state.business.name}</BodyTypography>
            </Grid>
            <Spacer size="m" />
            <Grid item container rowSpacing={1}>
              <Grid item xs={12}>
                <Typography fontWeight="bold">Staff</Typography>
              </Grid>
              <Grid container item columnSpacing={2}>
                <Grid item>
                  <Grid container alignItems="center">
                    <Avatar
                      src={location.state.class.staff.imagePath}
                      sx={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <Spacer position="left" />
                    <Typography>{location.state.class.staff.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Spacer size="l" />
          </Grid>
        </PaddedView>
      );
    }
    if (location.state.paymentTransaction.packageCodeRedemption?.code) {
      return (
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid container justifyContent="space-between">
            <TitleTypography>Booking Details</TitleTypography>
            <Grid item display="flex" alignItems="center">
              <Typography
                fontSize={theme.fonts.fontSizes.size14}
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.colors.ui.error,
                  color: theme.palette.colors.ui.error,
                  paddingX: "10px",
                }}
              >
                NON-REFUNDABLE
              </Typography>
            </Grid>
          </Grid>

          <Spacer size="s" />
          <Typography fontSize={theme.fonts.fontSizes.size14}>
            {location.state.booking.session.class.category.label}
          </Typography>
          <Spacer size="s" />
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <TitleTypography>{location.state.booking.session.class.name}</TitleTypography>
              <Spacer position="left" />
              <BodyTypography color="primary" fontWeight="bold">
                {location.state.paymentTransaction.packageCodeRedemption.code}
              </BodyTypography>
            </Grid>
            <Spacer />
            <Grid item>
              <BodyTypography>
                {location.state.booking.session.startTime} (
                {location.state.booking.session.durationMins}
                mins) | {location.state.booking.session.date}
              </BodyTypography>
              <BodyTypography>{location.state.booking.session.business.name}</BodyTypography>
            </Grid>
            <Spacer size="m" />
            <Grid item container rowSpacing={1}>
              <Grid item xs={12}>
                <Typography fontWeight="bold">Staff</Typography>
              </Grid>
              <Grid container item columnSpacing={2}>
                <Grid item>
                  <Grid container alignItems="center">
                    <Avatar
                      src={location.state.booking.session.staff.imagePath}
                      sx={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <Spacer position="left" />
                    <Typography>{location.state.booking.session.staff.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Spacer size="l" />
          </Grid>
        </PaddedView>
      );
    }
    return (
      <PaddedView multiples={isMobile ? 2 : 4}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        <Typography fontSize={theme.fonts.fontSizes.size14}>
          {location.state.booking.session.class.category.label}
        </Typography>
        <Spacer size="s" />
        <Grid item>
          <Grid container justifyContent="space-between">
            <TitleTypography>{location.state.booking.session.class.name}</TitleTypography>
          </Grid>
          <Grid item xs={12}>
            <Spacer />
            {location.state.booking.session.memberPrice && (
              <Grid item display="flex" alignContent="center">
                <Typography fontWeight="bold" color="primary">
                  RM {location.state.booking.session.memberPrice} (MEMBER)
                </Typography>
              </Grid>
            )}

            <Grid item display="flex" alignContent="center">
              <Typography
                fontWeight="bold"
                color={location.state.booking.session.memberPrice ? "grey" : "primary"}
              >
                RM {location.state.booking.session.price}{" "}
                {location.state.booking.session.memberPrice && "(NON-MEMBER)"}
              </Typography>
            </Grid>
          </Grid>
          <Spacer />
          <Grid item>
            <BodyTypography>
              {location.state.booking.session.startTime} (
              {location.state.booking.session.durationMins}
              mins) | {location.state.booking.session.date}
            </BodyTypography>
            <BodyTypography>{location.state.booking.session.business.name}</BodyTypography>
          </Grid>
          <Spacer size="m" />
          <Grid item container rowSpacing={1}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">Staff</Typography>
            </Grid>
            <Grid container item columnSpacing={2}>
              <Grid item>
                <Grid container alignItems="center">
                  <Avatar
                    src={location.state.booking.session.staff.imagePath}
                    sx={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <Spacer position="left" />
                  <Typography>{location.state.booking.session.staff.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Spacer size="l" />
        </Grid>
        <HorizontalDivider spacerSize="l" />
        <TitleTypography>Payment Summary</TitleTypography>
        <Spacer size="m" />
        <Grid item>
          <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
            {location.state.booking.promoCode && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Promo Code Applied</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography color="primary" fontWeight="bold">
                    {location.state.booking.promoCode}
                  </BodyTypography>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <BodyTypography>Subtotal</BodyTypography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
            </Grid>
            {location.state.paymentTransaction.membershipDiscount &&
              location.state.paymentTransaction.membershipDiscount !== "0.00" && (
                <>
                  <Grid item xs={6}>
                    <BodyTypography>Membership Discount</BodyTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <BodyTypography>
                      -RM {location.state.paymentTransaction.membershipDiscount}
                    </BodyTypography>
                  </Grid>
                </>
              )}
            {location.state.paymentTransaction.voucherDiscount &&
              location.state.paymentTransaction.voucherDiscount !== "0.00" && (
                <>
                  <Grid item xs={6}>
                    <BodyTypography>Voucher Discount</BodyTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <BodyTypography>
                      -RM {location.state.paymentTransaction.voucherDiscount}
                    </BodyTypography>
                  </Grid>
                </>
              )}
            <Grid item xs={6}>
              <BodyTypography>Total Price</BodyTypography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    );
  };

  const appointmentReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <Grid container justifyContent="space-between">
        <TitleTypography>Booking Details</TitleTypography>
        <Grid item display="flex" alignItems="center">
          <Typography
            fontSize={theme.fonts.fontSizes.size14}
            sx={{
              border: "1px solid",
              borderColor: theme.palette.colors.ui.error,
              color: theme.palette.colors.ui.error,
              paddingX: "10px",
            }}
          >
            NON-REFUNDABLE
          </Typography>
        </Grid>
      </Grid>

      <Spacer size="s" />
      <Typography fontSize={theme.fonts.fontSizes.size14}>WELLNESS</Typography>
      <Spacer size="s" />
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <TitleTypography>{location.state.booking.session.title}</TitleTypography>
          <Spacer position="left" />
          <BodyTypography color="primary" fontWeight="bold">
            {location.state.booking.package.code}
          </BodyTypography>
        </Grid>
        <Spacer />
        <Grid item>
          <BodyTypography>
            {location.state.booking.session.startTime} (
            {location.state.booking.session.durationMins}
            mins) | {location.state.booking.session.date}
          </BodyTypography>
          <BodyTypography>{location.state.booking.location.name}</BodyTypography>
        </Grid>
        <Spacer size="l" />
      </Grid>
    </PaddedView>
  );

  const gymSubscriptionReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Subscription Details</TitleTypography>
      <Grid item>
        <PaddedView>
          <Grid container columnSpacing={2} alignContent="center">
            <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex" xs={4}>
              <img
                src={location.state.subscription.plan.imagePath}
                alt="planImage"
                width="100%"
                height={isMobile ? 90 : 150}
                style={{
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              display="grid"
              flexDirection="column"
              alignContent="center"
              marginY="10px"
              xs={8}
            >
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Typography fontWeight="bold" className="planTitle">
                    {location.state.subscription.plan.title}
                  </Typography>
                </Grid>
                <Spacer />
                <Grid item display="flex" alignContent="center">
                  <Typography>{location.state.subscription.plan.shortDescription}</Typography>
                </Grid>
                <Spacer />
                {location.state.subscription.plan.memberPrice && (
                  <Grid item display="flex" alignContent="center">
                    <Typography fontWeight="bold" color="primary">
                      RM {location.state.subscription.plan.memberPrice} (MEMBER)
                    </Typography>
                  </Grid>
                )}

                <Grid item display="flex" alignContent="center">
                  <Typography
                    fontWeight="bold"
                    color={location.state.subscription.plan.memberPrice ? "grey" : "primary"}
                  >
                    RM {location.state.subscription.plan.price}{" "}
                    {location.state.subscription.plan.memberPrice && "(NON-MEMBER)"}
                  </Typography>
                </Grid>
              </Grid>
              <Spacer />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
      <HorizontalDivider />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          {location.state.subscription.promoCode && (
            <>
              <Grid item xs={6}>
                <BodyTypography>Promo Code Applied</BodyTypography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <BodyTypography color="primary" fontWeight="bold">
                  {location.state.subscription.promoCode}
                </BodyTypography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>
          {location.state.paymentTransaction.membershipDiscount &&
            location.state.paymentTransaction.membershipDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Membership Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.membershipDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          {location.state.paymentTransaction.voucherDiscount &&
            location.state.paymentTransaction.voucherDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Voucher Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.voucherDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
      {location.state.paymentTransaction.subsequentPayment && (
        <>
          <HorizontalDivider />
          <TitleTypography>Subsequent Payments</TitleTypography>
          <Spacer size="m" />
          <Grid item>
            <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
              <Grid item xs={6}>
                <TitleTypography>Payment Date</TitleTypography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <TitleTypography>Total Price</TitleTypography>
              </Grid>
              {location.state.paymentTransaction.subsequentPayment.dates.map((item) => (
                <Grid item container xs={12} justifyContent="space-between" key={item}>
                  <Grid item xs={6}>
                    <BodyTypography>{item}</BodyTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <BodyTypography>
                      RM {location.state.paymentTransaction.subsequentPayment.price}
                    </BodyTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </PaddedView>
  );

  const membershipReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Membership Plan</TitleTypography>
      <Grid item>
        <PaddedView>
          <Grid container columnSpacing={2} alignContent="center">
            <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex" xs={4}>
              <img
                src={location.state.membership.plan.imagePath}
                alt="planImage"
                width="100%"
                height={isMobile ? 90 : 150}
                style={{
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              display="grid"
              flexDirection="column"
              alignContent="center"
              marginY="10px"
              xs={8}
            >
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Typography fontWeight="bold" className="planTitle">
                    {location.state.membership.plan.identifier}
                  </Typography>
                </Grid>
                <Spacer />
                <Grid item display="flex" alignContent="center">
                  <Typography>{location.state.membership.plan.shortDescription}</Typography>
                </Grid>
                <Grid item display="flex" alignContent="center">
                  <Spacer size="xs" position="top" />
                  <Typography fontWeight="bold" color="primary">
                    RM {location.state.membership.plan.price}
                  </Typography>
                </Grid>
              </Grid>
              <Spacer />
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
      <HorizontalDivider />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>
          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );

  const packageReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Package Details</TitleTypography>
      <Grid item>
        <PaddedView>
          <Grid container columnSpacing={2} alignContent="center">
            <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex" xs={4}>
              <img
                src={location.state.package.plan.imagePath}
                alt="planImage"
                width="100%"
                height={isMobile ? 90 : 150}
                style={{
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item alignItems="center" display="flex" marginY="10px" xs={8}>
              <Grid item>
                <Grid item>
                  <Typography
                    fontWeight="bold"
                    className="planTitle"
                    sx={{ color: theme.palette.colors.brand.primary }}
                  >
                    {location.state.package.plan.title}
                  </Typography>
                </Grid>
                <Spacer />
                {location.state.package.plan.memberPrice && (
                  <Grid item display="flex" alignContent="center">
                    <Typography fontWeight="bold" color="primary">
                      RM {location.state.package.plan.memberPrice} (MEMBER)
                    </Typography>
                  </Grid>
                )}

                <Grid item display="flex" alignContent="center">
                  <Typography
                    fontWeight="bold"
                    color={location.state.package.plan.memberPrice ? "grey" : "primary"}
                  >
                    RM {location.state.package.plan.price}{" "}
                    {location.state.package.plan.memberPrice && "(NON-MEMBER)"}
                  </Typography>
                </Grid>
                <Grid item display="flex" alignContent="center">
                  <Typography>{location.state.package.plan.noOfSessions} sessions</Typography>
                </Grid>
                <Spacer />
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
      <HorizontalDivider />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          {location.state.package.promoCode && (
            <>
              <Grid item xs={6}>
                <BodyTypography>Promo Code Applied</BodyTypography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <BodyTypography color="primary" fontWeight="bold">
                  {location.state.package.promoCode}
                </BodyTypography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>
          {location.state.paymentTransaction.membershipDiscount &&
            location.state.paymentTransaction.membershipDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Membership Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.membershipDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          {location.state.paymentTransaction.voucherDiscount &&
            location.state.paymentTransaction.voucherDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Voucher Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.voucherDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );

  const eventReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Event Details</TitleTypography>
      <Grid
        item
        sx={{
          backgroundColor: theme.palette.colors.bg.primary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          objectFit: "cover",
        }}
      >
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={location.state.booking.event.image}
              alt="eventImage"
              width={isMobile ? 100 : 200}
              height={isMobile ? 100 : 150}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontSize={isMobile ? 14 : 16}
                  fontWeight="bold"
                  sx={{ textTransform: "capitalize" }}
                >
                  {location.state.booking.event.session.title
                    ? location.state.booking.event.session.title
                    : location.state.booking.event.title}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <CalendarMonthOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography fontSize={isMobile ? 14 : 16}>
                  {location.state.booking.event.session.date}
                </Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <AccessTimeOutlinedIcon color="primary" />
                <Spacer position="left" />
                <Typography fontSize={isMobile ? 14 : 16}>
                  {location.state.booking.event.session.time}
                </Typography>
              </Grid>
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography fontSize={isMobile ? 14 : 16}>
                  {location.state.booking.event.location.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item alignContent="center">
              {!location.state.booking.event.session.multipleCategories && (
                <>
                  <Spacer />
                  {location.state.booking.event.session.memberPrice && (
                    <Grid item display="flex" alignContent="center">
                      <Typography fontWeight="bold" color="primary">
                        RM {location.state.booking.event.session.memberPrice} (MEMBER)
                      </Typography>
                    </Grid>
                  )}

                  <Grid item display="flex" alignContent="center">
                    <Typography
                      fontWeight="bold"
                      color={location.state.booking.event.session.memberPrice ? "grey" : "primary"}
                    >
                      RM {location.state.booking.event.session.price}{" "}
                      {location.state.booking.event.session.memberPrice && "(NON-MEMBER)"}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {location.state.booking.event.sessionCategories.length > 0 && (
            <Grid item xs={12}>
              <Spacer size="l" />
              <Grid item>
                <Typography fontSize={isMobile ? 14 : 16} fontWeight="bold">
                  Selected Categories
                </Typography>
              </Grid>
              <Spacer />
              {location.state.booking.event.sessionCategories.map((item) => (
                <Grid item key={item.id}>
                  <Grid item>
                    <Typography fontSize={isMobile ? 14 : 16} fontWeight={600}>
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Grid item>
                        <Typography
                          fontSize={isMobile ? 14 : 16}
                          sx={{ color: theme.palette.colors.text.tertiary }}
                        >
                          {item.date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          fontSize={isMobile ? 14 : 16}
                          sx={{ color: theme.palette.colors.text.tertiary }}
                        >
                          {item.time}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid item>
                        {isMobile && <Spacer />}
                        {item.memberPrice && (
                          <Grid item display="flex" alignContent="center">
                            <Typography
                              fontSize={isMobile ? 14 : 16}
                              fontWeight="bold"
                              color="primary"
                            >
                              RM {item.memberPrice} (MEMBER)
                            </Typography>
                          </Grid>
                        )}

                        <Grid item display="flex" alignContent="center">
                          <Typography
                            fontSize={isMobile ? 14 : 16}
                            fontWeight="bold"
                            color={item.memberPrice ? "grey" : "primary"}
                          >
                            RM {item.price} {item.memberPrice && "(NON-MEMBER)"}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <Typography fontSize={isMobile ? 14 : 16} fontWeight="bold" color="primary">
                        RM {item.price}
                      </Typography> */}
                    </Grid>
                  </Grid>
                  <Spacer />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <HorizontalDivider />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          {location.state.paymentTransaction.promoCodeRedemption.code && (
            <Grid item xs={12}>
              <BodyTypography>
                Promo Code Applied:{" "}
                <span style={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                  {location.state.paymentTransaction.promoCodeRedemption.code}
                </span>
              </BodyTypography>
            </Grid>
          )}

          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>
          {location.state.paymentTransaction.membershipDiscount &&
            location.state.paymentTransaction.membershipDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Membership Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.membershipDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          {location.state.paymentTransaction.voucherDiscount &&
            location.state.paymentTransaction.voucherDiscount !== "0.00" && (
              <>
                <Grid item xs={6}>
                  <BodyTypography>Voucher Discount</BodyTypography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <BodyTypography>
                    -RM {location.state.paymentTransaction.voucherDiscount}
                  </BodyTypography>
                </Grid>
              </>
            )}
          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );

  const sportReceipt = () => (
    <PaddedView multiples={isMobile ? 2 : 4}>
      <TitleTypography>Sport Booking</TitleTypography>
      <Grid container columnSpacing={2} alignContent="center">
        <Grid item xs={4} alignSelf="center" paddingY="5px" alignItems="center" display="flex">
          <img
            src={location.state.booking.business.images[0].imagePath}
            alt="businessImage"
            width="100%"
            height={isMobile ? 100 : 140}
            style={{
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          display="grid"
          flexDirection="column"
          alignContent="space-between"
          marginY="10px"
        >
          <Grid item>
            <Grid item>
              <Typography
                fontWeight="bold"
                className="businessTitle"
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {location.state.booking.business.name}
              </Typography>
            </Grid>
            <Spacer />
            <Grid item display="flex" alignContent="center">
              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {location.state.booking.business.location.address}
              </Typography>
            </Grid>
            <Spacer />
            <Grid item display="flex" alignContent="center">
              <PlaceIcon sx={{ color: theme.palette.colors.brand.primary }} />
              <Spacer position="left" />
              <Typography>{location.state.booking.business.location.city}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Typography fontWeight="bold">Slot</Typography>
      <Spacer size="s" />
      <Grid item container justifyContent="space-between">
        <Typography>Date</Typography>
        <Typography>{location.state.booking.date}</Typography>
      </Grid>
      <Spacer size="s" />
      <Grid item container justifyContent="space-between">
        <Typography>Time</Typography>
        <Typography>{location.state.booking.time}</Typography>
      </Grid>
      <Spacer size="m" />
      <Typography fontWeight="bold">Courts</Typography>
      <Spacer size="s" />
      {location.state?.booking.courts.map((item) => (
        <>
          <Grid item container justifyContent="space-between">
            <Typography>{item.label}</Typography>
            <Typography>RM {item.price}</Typography>
          </Grid>
          <Spacer size="s" />
        </>
      ))}
      <HorizontalDivider />
      <TitleTypography>Payment Summary</TitleTypography>
      <Spacer size="m" />
      <Grid item>
        <Grid container item dispaly="flex" justifyContent="space-between" rowSpacing={1}>
          <Grid item xs={6}>
            <BodyTypography>Subtotal</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.subtotal}</BodyTypography>
          </Grid>

          <Grid item xs={6}>
            <BodyTypography>Total Price</BodyTypography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <BodyTypography>RM {location.state.paymentTransaction.total}</BodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );

  const renderReceipt = () => {
    switch (location.pathname) {
      case routes.HOURLYBOOKINGPAYMENTSUCCESS:
        return gymSessionBookingReceipt();
      case routes.PACKAGE_HOURLY_BOOKING_PAYMENT_SUCCESS:
        return packageGymSessionBookingReceipt();
      case routes.SUBSCRIPTIONPLANPAYMENTSUCCESS:
        return gymSubscriptionReceipt();
      case routes.MEMBERSHIP_PLAN_PAYMENT_SUCCESS:
        return membershipReceipt();
      case routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS:
        return fitnessClassSessionBookingReceipt();
      case routes.APPOINTMENTPAYMENTSUCCESS:
        return appointmentReceipt();
      case routes.PACKAGEPAYMENTSUCCESS:
        return packageReceipt();
      case routes.EVENTBOOKINGPAYMENTSUCCESS:
        return eventReceipt();
      case routes.SPORT_BOOKING_PAYMENT_SUCCESS:
        return sportReceipt();
      default:
        return false;
    }
  };

  return (
    <StyledContainer>
      <Spacer size="xxl" />
      <CircleTick />
      <Spacer size="xl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Successful!</StyledTitle>
      <Spacer size="m" />
      <GreyText sx={{ maxWidth: "70%" }}>{renderMessage()}</GreyText>
      <Spacer size="l" />
      {remoteBillId && <GreyText>{`REF ID: ${remoteBillId}`}</GreyText>}
      <Spacer size="xl" />
      {renderReceipt() && (
        <>
          <PaddedView multiples={1}>
            <Grid
              item
              xs={12}
              sx={{
                minWidth: isMobile ? "100%" : "600px",
                maxWidth: isMobile ? "100%" : "600px",
                backgroundColor: theme.palette.colors.bg.primary,
                borderRadius: `${theme.shape.borderRadius[2]}px`,
              }}
            >
              {renderReceipt()}
            </Grid>
          </PaddedView>
          <Spacer size="m" />
        </>
      )}
      <Grid container justifyContent="center" columnSpacing={2}>
        <Grid item>
          <CustomButton
            onClick={() => navigate(routes.HOME)}
            width="220px"
            fontSize={theme.fonts.fontSizes.size18}
          >
            Back to Home
          </CustomButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
