import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { getBusinesses } from "../../../services/search/sport/search-sport-slice.service";
import Filters from "../components/sport/filters.component";
import SportBusinessResultList from "../components/sport/sport-business-result-list.component";

const validationSchema = Yup.object().shape({
  lat: Yup.string().label("LocationLat"),
  long: Yup.string().label("LocationLong"),
  q: Yup.string().required().label("q"),
  distance: Yup.number().required().label("Distance"),
});

const ModalBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  padding: "10px",
  paddingBottom: "40px",
}));

function ExploreSportScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const [page, setPage] = useState(1);
  const [showPhoneViewBusinessList, setShowPhoneViewBusinessList] = useState(false);

  const handleSubmitSearch = (values) => {
    if (isMobile) {
      setShowPhoneViewBusinessList(true);
    }
    const payload = { ...values };

    payload.startAt = `${format(new Date(payload.date), "yyyy-MM-dd")} ${dayjs(
      new Date(payload.time),
    ).format("HH:mm:00")}`;
    dispatch(getBusinesses(payload)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    formRef.current.values.page = newPage;
    handleSubmitSearch(formRef.current.values);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box marginTop={isMobile ? 0 : 3}>
      <PaddedView multiples={2}>
        <Grid item>
          <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size24}>
            Discover the Best Local Sports Activities
          </Typography>
        </Grid>
        <Spacer size="l" />
        <Form
          innerRef={formRef}
          initialValues={{
            page: 1,
            q: "",
            distance: 50,
            lat: "",
            long: "",
            date: dayjs().add(1, "day").toDate(),
            time: dayjs().add(1, "day").hour(9).minute(0).second(0),
            startAt: "2025-01-15 15:00:00",
            duration: 2,
            categoriesId: [],
            tempCategoriesId: [],
            sportCategoryId: null,
            tempSportCategoryId: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSearch}
        >
          <Grid container columnSpacing={6}>
            <Grid item xs={isMobile ? 12 : 5}>
              <Filters />
            </Grid>
            {!isMobile && (
              <Grid item xs={7}>
                <SportBusinessResultList onPageChange={onPageChange} page={page} />
              </Grid>
            )}
            <Modal open={showPhoneViewBusinessList}>
              <ModalBox>
                <Grid item xs={12}>
                  <CustomButton
                    width="auto"
                    variant="text"
                    fontSize={theme.fonts.fontSizes.size15}
                    onClick={() => {
                      setShowPhoneViewBusinessList(false);
                    }}
                  >
                    {"< Back"}
                  </CustomButton>
                </Grid>
                <SportBusinessResultList onPageChange={onPageChange} page={page} />
              </ModalBox>
            </Modal>
          </Grid>
        </Form>
      </PaddedView>
    </Box>
  );
}

export default ExploreSportScreen;
