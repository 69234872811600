import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CustomButton from "../../../../../components/button/custom-button.component";
import Form from "../../../../../components/forms/form.component";
import routes from "../../../../../components/navigation/routes";
import Loading from "../../../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../../../components/notification/dialog-box.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  createPackageHourlyBooking,
  packageHourlyBookingSelector,
  previewPackageHourlyBooking,
} from "../../../../../services/package/hourly-booking/package-hourly-booking-slice.service";
import { getProfileDetail } from "../../../../../services/profile/profile-slice.service";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  packageCode: Yup.string().required().label("Package Code"),
  slots: Yup.array()
    .of(
      Yup.object().shape({
        startAt: Yup.date().required(),
        endAt: Yup.date().required(),
      }),
    )
    .required()
    .label("Slots"),
});

function PackageRedeemHourlyPreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { previewPackageHourlyBookingObj } = useSelector(packageHourlyBookingSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showNextDay = (startAt, endAt) => {
    if (!startAt || !endAt) {
      return false;
    }

    const startDate = new Date(startAt);
    const endDate = new Date(endAt);

    // Compare the two dates
    if (startDate.getDate() !== endDate.getDate()) {
      return true;
    }

    return false;
  };

  const previewBooking = (values) => {
    dispatch(previewPackageHourlyBooking(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
      }

      if (meta.requestStatus === "rejected") {
        setIsLoading(false);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    dispatch(createPackageHourlyBooking(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        dispatch(getProfileDetail());
        navigate(routes.PACKAGE_HOURLY_BOOKING_PAYMENT_SUCCESS, { state: { ...payload.data } });
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    handleFormSubmission(values);
  };

  useEffect(() => {
    previewBooking(location.state);
  }, []);

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <DialogBox
        title="Booking is non-refundable."
        isShow={showConfirmModal}
        setIsShowAlert={setShowConfirmModal}
        message={
          <Grid item>
            <Typography sx={{ textAlign: "center" }}>
              By continuing, you agree that this booking cannot be cancelled or rescheduled.
            </Typography>
          </Grid>
        }
        isConfirmHandle={() => {
          setShowConfirmModal(false);
          formRef.current.handleSubmit();
        }}
        buttonText="Ok"
      />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {previewPackageHourlyBookingObj.status === "succeeded" ? (
          <Form
            innerRef={formRef}
            initialValues={{
              businessId: location.state.businessId,
              slots: location.state.slots,
              packageCode: location.state.packageCode,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Typography fontSize={theme.fonts.fontSizes.size14}>GYM SESSION</Typography>
                      <Spacer size="m" />
                      {previewPackageHourlyBookingObj.data?.slots.map((item) => (
                        <Grid item key={uuidv4()}>
                          <Grid container justifyContent="space-between">
                            <TitleTypography>{item.business.name}</TitleTypography>
                          </Grid>
                          <Spacer />
                          <Spacer />
                          <Grid item>
                            <BodyTypography>Date: {item.date}</BodyTypography>
                            <BodyTypography>
                              Time: {item.startTime} - {item.endTime}{" "}
                              {showNextDay(item.startAt, item.endAt) && "(next day)"}
                            </BodyTypography>
                            <BodyTypography>
                              Access Time: {item.accessTime} - {item.revokeTime}{" "}
                              {showNextDay(item.startAt, item.endAt) && "(next day)"}
                            </BodyTypography>
                          </Grid>
                          <Spacer size="l" />
                        </Grid>
                      ))}
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>Package Code</SectionTypography>
                      <Spacer size="m" />
                      <Grid item display="flex">
                        <Typography
                          sx={{
                            border: "1px solid",
                            borderRadius: `${theme.shape.borderRadius[1]}px`,
                            padding: "10px",
                            paddingX: "30px",
                            fontWeight: "bold",
                          }}
                        >
                          {location.state.packageCode}
                        </Typography>
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 3 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="220px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>RM 0.00</BodyTypography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <HorizontalDivider spacerSize="l" />
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>RM 0.00</SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <CustomButton
                            fontSize={theme.fonts.fontSizes.size16}
                            onClick={() => {
                              setShowConfirmModal(true);
                            }}
                          >
                            Confirm
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default PackageRedeemHourlyPreviewSummaryScreen;
