import { Box, FormHelperText, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";

function FormPickerTime({ name, disabled, submitOnChange }) {
  const { setFieldValue, values, touched, errors, handleSubmit } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [open, setOpen] = useState(false);

  const handleChange = (time) => {
    setFieldValue(name, time);

    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "grid",
          flexDirection: "column",
          width: "100%",
          "& .MuiTextField-root": {
            justifySelf: "center",
          },
        }}
      >
        <TimePicker
          disabled={disabled}
          sx={{ justifySelf: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          inputFormat="h:mm a"
          onOpen={() => setOpen(true)}
          minutesStep={30} // Allows only 00 and 30 minutes
          timeSteps={{ hours: 1, minutes: 30 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onClick={() => setOpen(true)}
              InputProps={{
                sx: {
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "#006C57",
                  border: "2px solid #006C57",
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  width: "135px",
                },
              }}
            />
          )}
          value={values[name]}
          onChange={(time) => {
            handleChange(time);
          }}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormPickerTime.defaultProps = {
  disabled: false,
  disablePast: true,
  submitOnChange: false,
};

FormPickerTime.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

export default FormPickerTime;
