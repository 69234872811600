import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { Button, ButtonBase, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CoffeeLogo } from "../../../assets/images/coffee.svg";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import ImageCarousel from "../../../components/utils/image-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { sportSelector } from "../../../services/sport/sport-slice.service";
import { isLogin } from "../../../infrastructure/utils";

export default function CourtResultList({ setShowModal }) {
  const { getBusinessLayoutObj, getCourtsObj } = useSelector(sportSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { values } = useFormikContext();
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedCourts, setSelectedCourts] = useState([]);
  const [groupTimeShow, setGroupTimeShow] = useState([false, false, false]);
  const timeMappings = {
    morning: {
      icon: <CoffeeLogo width={35} height={35} stroke="#000" />,
      text: "Morning",
      period: "12:00 AM to 11:30 AM",
    },
    afternoon: {
      icon: <LightModeOutlinedIcon sx={{ fontSize: 35, color: "#000" }} />,
      text: "Afternoon",
      period: "12:00 PM to 4:30 PM",
    },
    evening: {
      icon: <DarkModeOutlinedIcon sx={{ fontSize: 35, color: "#000" }} />,
      text: "Evening",
      period: "5:00 PM to 11:30 PM",
    },
  };

  useEffect(() => {
    setSelectedTime(null);
    setSelectedDuration(null);
    setSelectedCourts([]);
    setGroupTimeShow([false, false, false]);
  }, [getCourtsObj.status]);

  useEffect(() => {
    if (!selectedTime) {
      setSelectedDuration(null);
      setSelectedCourts([]);
    }
    if (!selectedDuration) {
      setSelectedCourts([]);
    }
  }, [selectedTime, selectedDuration]);

  const checkout = () => {
    if (isLogin()) {
      navigate(routes.SPORT_BOOKING_SUMMARY, {
        state: {
          duration: selectedDuration.hour,
          date: format(new Date(values.date), "yyyy-MM-dd"),
          time: selectedTime.time,
          courtIds: selectedCourts,
        },
      });
    } else {
      navigate({
        pathname: routes.LOGIN,
      });
    }
  };

  const groupByTimePeriod = () => {
    const groupedData = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    getCourtsObj.data.forEach((item) => {
      const [hour, minute] = item.time.split(":").map(Number);
      const totalMinutes = hour * 60 + minute;

      if (totalMinutes >= 0 && totalMinutes <= 690) {
        // 12:00 AM (0) to 11:30 AM (690)
        groupedData.morning.push(item);
      } else if (totalMinutes >= 720 && totalMinutes <= 990) {
        // 12:00 PM (720) to 4:30 PM (990)
        groupedData.afternoon.push(item);
      } else if (totalMinutes >= 1020 && totalMinutes <= 1410) {
        // 5:00 PM (1020) to 11:30 PM (1410)
        groupedData.evening.push(item);
      }
    });

    return groupedData;
  };

  const renderGroupTimes = () => {
    if (selectedTime) {
      return (
        <Button
          size="large"
          sx={{ width: "100%", borderRadius: "10px" }}
          variant="outlined"
          startIcon={<AccessTimeIcon />}
          onClick={() => {
            setSelectedTime(null);
          }}
        >
          <Typography>
            {dayjs(
              new Date(`${format(new Date(values.date), "yyyy-MM-dd")} ${selectedTime.time}`),
            ).format("HH:mm A")}
          </Typography>
        </Button>
      );
    }

    if (getCourtsObj.status === "succeeded") {
      if (getCourtsObj.data.length > 0) {
        const groupedResult = groupByTimePeriod();

        const entity = [];

        Object.entries(groupedResult).forEach(([groupName, items], index) => {
          if (items.length > 0) {
            entity.push(
              <Grid item xs={12}>
                <ButtonBase
                  sx={{ width: "100% !important" }}
                  onClick={() => {
                    const newGroupTimeShow = [...groupTimeShow];
                    newGroupTimeShow[index] = !newGroupTimeShow[index];
                    setGroupTimeShow(newGroupTimeShow);
                  }}
                >
                  <Grid container xs={12} display="flex" alignItems="center">
                    <Grid item>{timeMappings[groupName].icon}</Grid>
                    <Spacer position="left" />
                    <Grid item textAlign="left">
                      <Typography sx={{ fontWeight: "bold" }}>
                        {timeMappings[groupName].text}
                      </Typography>
                      <Typography color="grey">{timeMappings[groupName].period}</Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
                <Spacer />
                {groupTimeShow[index] && (
                  <Grid item container rowSpacing={1} xs={12}>
                    {items.map((item) => (
                      <Grid item xs={12}>
                        <Button
                          color="grey"
                          onClick={() => setSelectedTime(item)}
                          size="large"
                          sx={{ width: "100%", borderRadius: "10px" }}
                          variant="outlined"
                        >
                          <Typography>
                            {dayjs(
                              new Date(
                                `${format(new Date(values.date), "yyyy-MM-dd")} ${item.time}`,
                              ),
                            ).format("HH:mm A")}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}

                <Spacer />
              </Grid>,
            );
          }
        });

        return entity;
      }

      return <Typography>No available slots. Please amend your booking.</Typography>;
    }

    return (
      <Grid item xs={12}>
        <CustomSkeleton width="100%" height="100px" />
      </Grid>
    );
  };

  return (
    <Grid item xs={12} height={isMobile ? undefined : "100%"}>
      <Grid
        item
        display="grid"
        flexDirection="column"
        alignContent="space-between"
        xs={12}
        height="100%"
      >
        <Grid item xs={12} container rowSpacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", paddingBottom: "10px", textAlign: "center" }}
            >
              Book Slot
            </Typography>
          </Grid>

          {getBusinessLayoutObj.status === "pending" && (
            <Grid item container rowSpacing={1} xs={12}>
              <Grid item xs={12}>
                <CustomSkeleton width="100%" height="250px" />
              </Grid>
            </Grid>
          )}
          {getBusinessLayoutObj.status === "succeeded" && (
            <Grid item xs={12}>
              <ImageCarousel imageList={getBusinessLayoutObj.data} />
            </Grid>
          )}
          {getCourtsObj.status === "pending" && (
            <Grid item container rowSpacing={1} xs={12}>
              <Grid item xs={12}>
                <CustomSkeleton width="100%" height="400px" />
              </Grid>
            </Grid>
          )}
          {getCourtsObj.status === "succeeded" && (
            <Grid item container rowSpacing={1} xs={12}>
              <Grid item xs={12}>
                <Grid item>Selected Date</Grid>
                <Button
                  onClick={() => setShowModal(false)}
                  size="large"
                  sx={{ width: "100%", borderRadius: "10px" }}
                  variant="outlined"
                  startIcon={<CalendarMonthIcon />}
                >
                  <Typography>{format(new Date(values.date), "yyyy-MM-dd")}</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography>Start Time</Typography>
                {!selectedTime && (
                  <Typography color="grey">Please select your start time</Typography>
                )}

                {renderGroupTimes()}
              </Grid>
              <Grid item xs={12}>
                <Typography>Duration</Typography>
                {!selectedTime && (
                  <Typography color="grey">
                    Please select your start time to show the available durations
                  </Typography>
                )}
                {!selectedDuration &&
                  selectedTime &&
                  selectedTime.courtQuotationWithBookableHours.length > 0 && (
                    <Grid item xs={12} container rowSpacing={1}>
                      {selectedTime.courtQuotationWithBookableHours.map((item) => (
                        <Grid item xs={12}>
                          <Button
                            color="grey"
                            onClick={() => setSelectedDuration(item)}
                            size="large"
                            sx={{ width: "100%", borderRadius: "10px", textTransform: "none" }}
                            variant="outlined"
                          >
                            <Typography>{item.hour} hour(s)</Typography>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                {selectedDuration && (
                  <Button
                    onClick={() => setSelectedDuration(null)}
                    size="large"
                    sx={{ width: "100%", borderRadius: "10px", textTransform: "none" }}
                    variant="outlined"
                  >
                    <Typography>{selectedDuration.hour} hour(s)</Typography>
                  </Button>
                )}
                {selectedTime && selectedTime.courtQuotationWithBookableHours.length === 0 && (
                  <Typography color="grey">No durations available</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Court(s)</Typography>
                {!selectedDuration && (
                  <Typography color="grey">
                    Please select your start time and duration for the available courts
                  </Typography>
                )}
                {selectedDuration && selectedDuration.courts.length === 0 && (
                  <Typography color="grey">No courts available</Typography>
                )}
                {selectedDuration && selectedDuration.courts.length > 0 && (
                  <Grid item xs={12} container rowSpacing={1}>
                    {selectedDuration.courts.map((item) => (
                      <Grid item xs={12}>
                        <Button
                          color={selectedCourts.includes(item.id) ? "primary" : "grey"}
                          onClick={() => {
                            if (selectedCourts.includes(item.id)) {
                              setSelectedCourts(selectedCourts.filter((id) => id !== item.id));
                            } else {
                              setSelectedCourts([...selectedCourts, item.id]);
                            }
                          }}
                          size="large"
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            textTransform: "none",
                            display: "block",
                          }}
                          variant="outlined"
                        >
                          <Grid
                            item
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: selectedCourts.includes(item.id) ? "bold" : undefined,
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: selectedCourts.includes(item.id) ? "bold" : undefined,
                              }}
                            >
                              RM {item.price}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item display="flex" justifyContent="center" xs={12} sx={{ marginTop: "20px" }}>
          <CustomButton
            onClick={checkout}
            width={300}
            fontSize="16px"
            disabled={selectedCourts.length === 0}
          >
            Checkout
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

CourtResultList.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
