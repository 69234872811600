import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../../infrastructure/utils";
import { getReq, postReq } from "../../api.services";

export const previewSportBooking = createAsyncThunk("sports/booking/preview", async (payload) => {
  const response = await postReq(`${getAPI()}/sports/bookings/preview`, payload);
  return response;
});

export const createSportBooking = createAsyncThunk("sports/bookings/create", async (payload) => {
  const response = await postReq(`${getAPI()}/sports/bookings`, {
    ...payload,
    platform: "web",
  });
  return response;
});

export const getLiveAvailability = createAsyncThunk(
  "sports/bookings/live_availabilities",
  async (payload) => {
    const response = await getReq(
      `${getAPI()}/sports/bookings/live_availabilities?businessId=${payload.businessId}&q=${
        payload.q
      }&date=${payload.date}`,
    );
    return response;
  },
);

const sportBookingSlice = createSlice({
  name: "sportBooking",
  initialState: {
    previewSportBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSportBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getLiveAvailabilityObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewSportBooking.pending]: (state) => {
      state.previewSportBookingObj.status = "pending";
    },
    [previewSportBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewSportBookingObj.status = "succeeded";
      state.previewSportBookingObj.data = data;
      state.previewSportBookingObj.successMessage = message;
    },
    [previewSportBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewSportBookingObj.status = "failed";
      state.previewSportBookingObj.errorMessage = message;
    },
    [createSportBooking.pending]: (state) => {
      state.createSportBookingObj.status = "pending";
    },
    [createSportBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSportBookingObj.status = "succeeded";
      state.createSportBookingObj.data = data;
      state.createSportBookingObj.successMessage = message;
    },
    [createSportBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSportBookingObj.status = "failed";
      state.createSportBookingObj.errorMessage = message;
    },
    [getLiveAvailability.pending]: (state) => {
      state.getLiveAvailabilityObj.status = "pending";
    },
    [getLiveAvailability.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getLiveAvailabilityObj.status = "succeeded";
      state.getLiveAvailabilityObj.data = data;
      state.getLiveAvailabilityObj.successMessage = message;
    },
    [getLiveAvailability.rejected]: (state, action) => {
      const { message } = action.error;

      state.getLiveAvailabilityObj.status = "failed";
      state.getLiveAvailabilityObj.errorMessage = message;
    },
  },
});

export default sportBookingSlice.reducer;

// state
export const sportBookingSelector = (state) => state.sportBooking;
