import PlaceIcon from "@mui/icons-material/Place";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function BusinessResultCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { values } = useFormikContext();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      onClick={() =>
        navigate(
          {
            pathname: routes.BUSINESS_SPORT,
            search: `?id=${itemDetails.id}&q=${values.q}&categoryId=${
              values.sportCategoryId
            }&date=${format(new Date(values.date), "yyyy-MM-dd")}&time=${dayjs(
              new Date(values.time),
            ).format("HH:mm:00")}&duration=${values.duration}`,
          },
          {
            state: {
              categoriesLabel: [values.q],
            },
          },
        )
      }
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .businessTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={isMobile ? 1 : 2}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item xs={4} alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={itemDetails.images[0].imagePath}
              alt="businessImage"
              width="100%"
              height={isMobile ? 100 : 150}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                <Typography
                  fontWeight="bold"
                  className="businessTitle"
                  maxWidth={width < 450 ? "180px" : "none"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.name}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <PlaceIcon sx={{ color: theme.palette.colors.brand.primary }} />
                <Spacer position="left" />
                <Typography>{itemDetails.location.city}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid",
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: theme.palette.colors.brand.primary,
                  borderRadius: "5px",
                }}
              >
                <Typography sx={{ fontWeight: "bold", color: theme.palette.colors.brand.primary }}>
                  Book
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>Available sport(s): {itemDetails.sports.join(", ")}</Typography>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

BusinessResultCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    name: "",
    slug: "",
    city: "",
    exclusiveReferrals: false,
    requireMembership: false,
    reviews: PropTypes.shape({
      averageRatings: "",
    }),
    sports: [],
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: "",
      }),
    ),
  }),
};

BusinessResultCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    sports: PropTypes.arrayOf(PropTypes.string),
    distanceKM: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
      }),
    ),
  }),
};

export default BusinessResultCard;
