import { Grid, List, ListItem, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "../../../../components/utils/spacer.component";

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  padding: "20px",
  borderRadius: `${theme.shape.borderRadius[1]}px`,
}));

function PackageDetailsSection({ itemDetails }) {
  const theme = useTheme();

  return (
    <>
      <SectionContainer>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Package</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">{itemDetails.plan.title}</Typography>
          </Grid>
        </Grid>
        <Spacer />

        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Paid at</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">{itemDetails.paymentTransaction.paidAt}</Typography>
          </Grid>
        </Grid>

        <Spacer />

        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Redeem By</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">
              {itemDetails.redemptionExpiryDate ? itemDetails.redemptionExpiryDate : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Spacer />

        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography>Payment Method</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">{itemDetails.paymentTransaction.method}</Typography>
          </Grid>
        </Grid>
        <Spacer />

        <Grid item container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography>Total Price</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography textAlign="right" fontWeight="bold">
              RM {itemDetails.paymentTransaction.totalPrice}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={theme.fonts.fontSizes.size14}
              color={theme.palette.colors.text.secondary}
            >
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontWeight="bold"
              textAlign="right"
              fontSize={theme.fonts.fontSizes.size14}
              color={theme.palette.colors.text.secondary}
            >
              RM {itemDetails.paymentTransaction.price}
            </Typography>
          </Grid>
          {itemDetails.paymentTransaction.membershipDiscount !== "0.00" && (
            <>
              <Grid item xs={6}>
                <Typography
                  fontSize={theme.fonts.fontSizes.size14}
                  color={theme.palette.colors.text.secondary}
                >
                  Membership Discount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontWeight="bold"
                  textAlign="right"
                  fontSize={theme.fonts.fontSizes.size14}
                  color={theme.palette.colors.text.secondary}
                >
                  -RM {itemDetails.paymentTransaction.membershipDiscount}
                </Typography>
              </Grid>
            </>
          )}
          {itemDetails.paymentTransaction.voucherDiscount !== "0.00" && (
            <>
              <Grid item xs={6}>
                <Typography
                  fontSize={theme.fonts.fontSizes.size14}
                  color={theme.palette.colors.text.secondary}
                >
                  Voucher Discount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontWeight="bold"
                  textAlign="right"
                  fontSize={theme.fonts.fontSizes.size14}
                  color={theme.palette.colors.text.secondary}
                >
                  -RM {itemDetails.paymentTransaction.voucherDiscount}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </SectionContainer>

      <Spacer />

      <SectionContainer item>
        <Grid item>
          <Typography
            sx={{
              fontSize: theme.fonts.fontSizes.size14,
              color: theme.palette.colors.text.secondary,
            }}
          >
            Redeemed at:
          </Typography>
        </Grid>
        <List sx={{ listStyleType: "disc", padding: 0, pl: 3 }}>
          {itemDetails.businesses.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                display: "list-item",
                textAlign: "justify",
                padding: 0,
                fontSize: theme.fonts.fontSizes.size14,
              }}
            >
              {item.name}
            </ListItem>
          ))}
        </List>
      </SectionContainer>
      <Spacer />

      <SectionContainer item>
        <Grid item sx={{ fontSize: theme.fonts.fontSizes.size14 }}>
          <Typography
            sx={{
              fontSize: theme.fonts.fontSizes.size14,
              color: theme.palette.colors.text.secondary,
            }}
          >
            Categories:{" "}
            <span
              style={{
                color: theme.palette.colors.text.primary,
                textTransform: "capitalize",
              }}
            >
              {itemDetails.category.type.replace("_", " ")}
            </span>
          </Typography>
        </Grid>
        <Spacer />
        <Grid container columnSpacing={1}>
          {itemDetails.category.items.map((item) => (
            <Grid item key={item.id}>
              <Typography
                fontSize={theme.fonts.fontSizes.size12}
                sx={{
                  border: "1px solid",
                  borderRadius: `${theme.shape.borderRadius[1]}px`,
                  padding: "2px",
                  paddingX: "4px",
                }}
              >
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </SectionContainer>
    </>
  );
}

PackageDetailsSection.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    redemptionExpiryDate: PropTypes.string,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      totalPrice: PropTypes.string,
      price: PropTypes.string,
      voucherDiscount: PropTypes.string,
      membershipDiscount: PropTypes.string,
    }),
  }).isRequired,
};

export default PackageDetailsSection;
