import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIV2 } from "../../../infrastructure/utils";
import { getReq } from "../../api.services";

export const getBusinesses = createAsyncThunk("searches/sports/businesses", async (payload) => {
  const { page, q, startAt, duration, lat, long, distance } = payload;
  const response = await getReq(
    `${getAPIV2()}/searches/sports/businesses?page=${page}${
      q ? "&q=".concat(q) : ""
    }&startAt=${startAt}&duration=${duration}&lat=${lat}&long=${long}&distance=${distance}`,
  );
  return response;
});

const searchSportSlice = createSlice({
  name: "searchSport",
  initialState: {
    getBusinessesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBusinesses.pending]: (state) => {
      state.getBusinessesObj.status = "pending";
    },
    [getBusinesses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessesObj.status = "succeeded";
      state.getBusinessesObj.data = data;
      state.getBusinessesObj.successMessage = message;
    },
    [getBusinesses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessesObj.status = "failed";
      state.getBusinessesObj.errorMessage = message;
    },
  },
});

export default searchSportSlice.reducer;

// state
export const searchSportSelector = (state) => state.searchSport;
