import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/button/custom-button.component";
import Spacer from "../../../../components/utils/spacer.component";
import PackageDetailsSection from "./package-details-section.component";
import PackageRedeemHistory from "./package-redeem-history.component";
import AvailableScheduleModal from "./available-schedule-modal.component";
import AvailableHourlyBookingModal from "./available-hourly-booking-modal.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
  overflowY: "auto",
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  padding: "20px",
  borderRadius: `${theme.shape.borderRadius[1]}px`,
}));

function PackageDetailsModal({
  itemDetails,
  showModal,
  setShowModal,
  setShowAvailablePackageSessionModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [infoTab, setInfoTab] = useState("details");
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showHourlyBookingModal, setShowHourlyBookingModal] = useState(false);

  useEffect(() => {
    setInfoTab("details");
  }, [itemDetails]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        {itemDetails && (
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            height="98%"
            xs={12}
            padding="20px"
          >
            {itemDetails.category.type === "hourly_booking" && (
              <AvailableHourlyBookingModal
                itemDetails={itemDetails}
                showModal={showHourlyBookingModal}
                setShowModal={setShowHourlyBookingModal}
              />
            )}
            {itemDetails.category.type !== "fitness" &&
              itemDetails.category.type !== "hourly_booking" && (
                <AvailableScheduleModal
                  itemDetails={itemDetails}
                  showModal={showScheduleModal}
                  setShowModal={setShowScheduleModal}
                />
              )}

            <Grid item>
              <Grid container flexDirection="column" rowSpacing={2}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                      Package Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Spacer />
                <SectionContainer container item alignItems="center">
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Typography onClick={() => handleCopy(itemDetails.code)}>
                        Code:{" "}
                        <span
                          style={{
                            backgroundColor: theme.palette.colors.brand.primary,
                            color: theme.palette.colors.brand.white,
                            padding: "5px 20px",
                            borderRadius: "20px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {itemDetails.code}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {itemDetails.sessions.claimedCount} / {itemDetails.sessions.noOfSessions}{" "}
                        sessions
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Spacer />
                    <LinearProgress
                      variant="determinate"
                      value={
                        (itemDetails.sessions.claimedCount / itemDetails.sessions.noOfSessions) *
                        100
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Spacer />
                    <Typography
                      sx={{ color: theme.palette.colors.text.secondary }}
                      fontSize={theme.fonts.fontSizes.size14}
                    >
                      Used: {itemDetails.sessions.claimedCount} sessions
                    </Typography>
                  </Grid>
                </SectionContainer>

                <Spacer size="m" />

                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <CustomButton
                      buttonColor={infoTab === "details" ? "primary" : "grey"}
                      textColor={infoTab === "details" ? "primary" : "grey"}
                      variant="outlined"
                      fontSize={theme.fonts.fontSizes.size16}
                      onClick={() => {
                        setInfoTab("details");
                      }}
                    >
                      Details
                    </CustomButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomButton
                      buttonColor={infoTab === "history" ? "primary" : "grey"}
                      textColor={infoTab === "history" ? "primary" : "grey"}
                      variant="outlined"
                      fontSize={theme.fonts.fontSizes.size16}
                      onClick={() => {
                        setInfoTab("history");
                      }}
                    >
                      History
                    </CustomButton>
                  </Grid>
                </Grid>
                <Spacer size="m" />
                <Box>
                  <Grid item sx={{ overflowY: "auto", height: !isMobile && "300px" }}>
                    {infoTab === "details" ? (
                      <PackageDetailsSection itemDetails={itemDetails} />
                    ) : (
                      <PackageRedeemHistory itemDetails={itemDetails} />
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid item padding="10px">
              <Spacer />
              <CustomButton
                disabled={itemDetails.sessions.claimedCount === itemDetails.sessions.noOfSessions}
                fontSize={theme.fonts.fontSizes.size16}
                onClick={() => {
                  if (itemDetails.category.type === "fitness") {
                    setShowAvailablePackageSessionModal(true);
                  } else if (itemDetails.category.type === "hourly_booking") {
                    setShowHourlyBookingModal(true);
                  } else {
                    setShowScheduleModal(true);
                  }
                }}
              >
                Redeem
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </ModalBox>
    </Modal>
  );
}

PackageDetailsModal.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    code: "",
    plan: PropTypes.shape({
      title: "",
      imagePath: "",
    }),
    sessions: PropTypes.shape({
      claimedCount: 0,
      noOfSessions: 0,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
      }),
    ),
    category: PropTypes.shape({
      type: "",
      model: "",
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: null,
          label: "",
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: "",
      paidAt: "",
      total: "",
    }),
  }),
};

PackageDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowAvailablePackageSessionModal: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }),
};

export default PackageDetailsModal;
