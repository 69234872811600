import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, styled, Tab, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import BottomBarContainer from "../../../components/utils/bottom-bar-container.component";
import Spacer from "../../../components/utils/spacer.component";
import { categorySelector } from "../../../services/category/category-slice.service";
import CategoryFilterBox from "./category-filter-box.component";
import { sportSelector } from "../../../services/sport/sport-slice.service";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.fonts.fontSizes.size15,
}));

function CategoryFilterTab({
  searchFor,
  selectedCategory,
  setSelectedCategory,
  setShowPhoneViewCategoryFilter,
  setShowFilterCategoryTabs,
  setTempSelectedCategory,
  showPhoneViewCategoryFilter,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getCategoryObj } = useSelector(categorySelector);
  const { getBusinessCategoriesObj } = useSelector(sportSelector);
  const { setFieldValue, values, handleSubmit } = useFormikContext();
  const [tabs, setTabs] = useState(searchFor === "event" ? "event" : values.tag || "fitness");
  const location = useLocation();
  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const handleSelectCategory = (itemId, categoryId, categoryLabel) => {
    if (searchFor === "sport") {
      // Single select: Replace the existing selection
      setFieldValue("categoriesId", [categoryId]);
      setSelectedCategory([categoryLabel]);
      setFieldValue("sportCategoryId", itemId); // Add sport-specific category handling
      setFieldValue("q", categoryLabel); // Add sport-specific category handling
      return;
    }
    // Multi-select logic (for "classes" & "event")
    if (values.categoriesId.includes(categoryId)) {
      setFieldValue(
        "categoriesId",
        values.categoriesId.filter((id) => categoryId !== id),
      );
      setSelectedCategory(selectedCategory.filter((label) => categoryLabel !== label));

      if (searchFor === "classes") {
        setFieldValue(
          "fitnessCategoriesId",
          values.fitnessCategoriesId.filter((id) => itemId !== id),
        );
      } else if (searchFor === "event") {
        setFieldValue(
          "eventCategoriesId",
          values.eventCategoriesId.filter((id) => itemId !== id),
        );
      }
    } else {
      setFieldValue("categoriesId", [...values.categoriesId, categoryId]);
      setSelectedCategory([...selectedCategory, categoryLabel]);

      if (searchFor === "classes") {
        setFieldValue("fitnessCategoriesId", [...values.fitnessCategoriesId, itemId]);
      } else if (searchFor === "event") {
        setFieldValue("eventCategoriesId", [...values.eventCategoriesId, itemId]);
      }
    }
  };

  useEffect(() => {
    if (
      !["event", "sport"].includes(searchFor) &&
      values.tag === "" &&
      !showPhoneViewCategoryFilter
    ) {
      setTabs("fitness");
      values.categoriesId = [];
      values.fitnessCategoriesId = [];
      setSelectedCategory([]);
    }
    if (searchFor === "sport") {
      setTabs("sport");
    }
  }, [searchFor]);

  const renderTabCondition = (tag) => values.tag === tag || values.tag === "";

  return (
    <TabContext value={tabs}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} variant="fullWidth">
          {searchFor === "event" && (
            <StyledTab
              label={
                <div>
                  <span
                    style={{
                      color: theme.palette.colors.brand.primary,
                      fontSize: theme.fonts.fontSizes.size16,
                    }}
                  >
                    •{" "}
                  </span>
                  Event
                </div>
              }
              value="event"
            />
          )}
          {searchFor === "sport" && (
            <StyledTab
              label={
                <div>
                  <span
                    style={{
                      color: theme.palette.colors.brand.primary,
                      fontSize: theme.fonts.fontSizes.size16,
                    }}
                  >
                    •{" "}
                  </span>
                  Sport
                </div>
              }
              value="sport"
            />
          )}

          {searchFor !== "event" && renderTabCondition("fitness") && (
            <StyledTab
              label={
                <div>
                  {values.categoriesId.some((item) => item <= 16) && (
                    <span
                      style={{
                        color: theme.palette.colors.brand.primary,
                        fontSize: theme.fonts.fontSizes.size16,
                      }}
                    >
                      •{" "}
                    </span>
                  )}
                  Fitness
                </div>
              }
              value="fitness"
            />
          )}

          {renderTabCondition("wellness") && searchFor === "businesses" && (
            <StyledTab
              label={
                <div>
                  {values.categoriesId.some((item) => item >= 28 && item <= 38) && (
                    <span
                      style={{
                        color: theme.palette.colors.brand.primary,
                        fontSize: theme.fonts.fontSizes.size16,
                      }}
                    >
                      •{" "}
                    </span>
                  )}
                  Wellness
                </div>
              }
              value="wellness"
            />
          )}
          {renderTabCondition("health") && searchFor === "businesses" && (
            <StyledTab
              label={
                <div>
                  {values.categoriesId.some((item) => item >= 17 && item <= 27) && (
                    <span
                      style={{
                        color: theme.palette.colors.brand.primary,
                        fontSize: theme.fonts.fontSizes.size16,
                      }}
                    >
                      •{" "}
                    </span>
                  )}
                  Health
                </div>
              }
              value="health"
            />
          )}
        </TabList>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: isMobile ? "98%" : "400px",
          overflowY: "scroll",
        }}
      >
        {getCategoryObj.status === "succeeded" && (
          <>
            {searchFor === "event" && (
              <TabPanel value="event" sx={{ paddingX: "5px" }}>
                <Grid container justifyItems="center" rowSpacing={2}>
                  {getCategoryObj.data.event.map((item) => (
                    <Grid
                      item
                      xs={isMobile ? 6 : 4}
                      justifyContent="center"
                      display="flex"
                      key={item.id}
                    >
                      <CategoryFilterBox
                        itemDetails={item}
                        onClick={() => handleSelectCategory(item.id, item.categoryId, item.label)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {searchFor === "sport" && location.pathname !== routes.BUSINESS_SPORT && (
              <TabPanel value="sport" sx={{ paddingX: "5px" }}>
                <Grid container justifyItems="center" rowSpacing={2}>
                  {getCategoryObj.data.sport.map((item) => (
                    <Grid
                      item
                      xs={isMobile ? 6 : 4}
                      justifyContent="center"
                      display="flex"
                      key={item.id}
                    >
                      <CategoryFilterBox
                        itemDetails={item}
                        onClick={() => handleSelectCategory(item.id, item.categoryId, item.label)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {searchFor === "sport" &&
              getBusinessCategoriesObj.status === "succeeded" &&
              location.pathname === routes.BUSINESS_SPORT && (
                <TabPanel value="sport" sx={{ paddingX: "5px" }}>
                  <Grid container justifyItems="center" rowSpacing={2}>
                    {getBusinessCategoriesObj.data.map((item) => (
                      <Grid
                        item
                        xs={isMobile ? 6 : 4}
                        justifyContent="center"
                        display="flex"
                        key={item.id}
                      >
                        <CategoryFilterBox
                          itemDetails={item}
                          onClick={() => handleSelectCategory(item.id, item.id, item.label)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              )}
            {searchFor !== "event" && renderTabCondition("fitness") && (
              <TabPanel value="fitness" sx={{ paddingX: "5px" }}>
                <Grid container justifyItems="center" rowSpacing={2}>
                  {getCategoryObj.data.fitness.map((item) => (
                    <Grid
                      item
                      xs={isMobile ? 6 : 4}
                      justifyContent="center"
                      display="flex"
                      key={item.id}
                    >
                      <CategoryFilterBox
                        itemDetails={item}
                        onClick={() => handleSelectCategory(item.id, item.categoryId, item.label)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {renderTabCondition("wellness") && searchFor === "businesses" && (
              <TabPanel value="wellness" sx={{ paddingX: "5px" }}>
                <Grid container justifyItems="center" rowSpacing={2}>
                  {getCategoryObj.data.wellness.map((item) => (
                    <Grid
                      item
                      xs={isMobile ? 6 : 4}
                      justifyContent="center"
                      display="flex"
                      key={item.id}
                    >
                      <CategoryFilterBox
                        itemDetails={item}
                        onClick={() => handleSelectCategory(item.id, item.categoryId, item.label)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {renderTabCondition("health") && searchFor === "businesses" && (
              <TabPanel value="health" sx={{ paddingX: "5px" }}>
                <Grid container justifyItems="center" rowSpacing={2}>
                  {getCategoryObj.data.health.map((item) => (
                    <Grid
                      item
                      xs={isMobile ? 6 : 4}
                      justifyContent="center"
                      display="flex"
                      key={item.id}
                    >
                      <CategoryFilterBox
                        itemDetails={item}
                        onClick={() => handleSelectCategory(item.id, item.categoryId, item.label)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            )}
            {!isMobile && <Spacer size="xxl" />}
          </>
        )}
      </Box>
      <BottomBarContainer>
        <Grid container display="flex" justifyContent="center" columnSpacing={2}>
          <Grid item xs={5}>
            <CustomButton
              variant="outlined"
              fontSize={theme.fonts.fontSizes.size15}
              onClick={() => {
                setFieldValue("categoriesId", []);
                setFieldValue("fitnessCategoriesId", []);
                setFieldValue("eventCategoriesId", []);
                setSelectedCategory([]);
                setFieldValue("tempCategoriesId", []);
                setFieldValue("tempEventCategoriesId", []);
                setTempSelectedCategory([]);
                setShowPhoneViewCategoryFilter(false);
                setShowFilterCategoryTabs(false);
                setFieldValue("q", "");
                if (searchFor !== "sport") {
                  handleSubmit();
                }
              }}
            >
              Reset
            </CustomButton>
          </Grid>
          <Grid item xs={5}>
            <CustomButton
              fontSize={theme.fonts.fontSizes.size15}
              onClick={() => {
                setShowPhoneViewCategoryFilter(false);
                setShowFilterCategoryTabs(false);
                setFieldValue("tempCategoriesId", values.categoriesId);
                setFieldValue("tempEventCategoriesId", values.eventCategoriesId);
                setTempSelectedCategory(selectedCategory);
                if (searchFor !== "sport") {
                  handleSubmit();
                }
              }}
            >
              Apply
            </CustomButton>
          </Grid>
        </Grid>
      </BottomBarContainer>
    </TabContext>
  );
}

CategoryFilterTab.defaultProps = {
  searchFor: "businesses",
  showPhoneViewCategoryFilter: false,
};

CategoryFilterTab.propTypes = {
  searchFor: PropTypes.string,
  selectedCategory: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  setShowPhoneViewCategoryFilter: PropTypes.func.isRequired,
  setShowFilterCategoryTabs: PropTypes.func.isRequired,
  setTempSelectedCategory: PropTypes.func.isRequired,
  showPhoneViewCategoryFilter: PropTypes.bool,
};

export default CategoryFilterTab;
