import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../../../infrastructure/utils";
import { getReq, patchReq } from "../../../api.services";

export const cancelSportBookingBill = createAsyncThunk(
  "sports/bookings/bill/{billId}/cancel",
  async (payload) => {
    const { billId } = payload;
    const response = await patchReq(`${getAPI()}/sports/bookings/bills/${billId}/cancel`);
    return response;
  },
);

export const getSportBillViaRemoteBillId = createAsyncThunk(
  "sports/bookings/bills/{billId}",
  async (payload) => {
    const { remoteBillId } = payload;
    const response = getReq(`${getAPI()}/sports/bookings/bills/${remoteBillId}`, payload);
    return response;
  },
);

const sportBookingBillSlice = createSlice({
  name: "sportBookingBill",
  initialState: {
    cancelSportBookingBillObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSportBillViaRemoteBillIdObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [cancelSportBookingBill.pending]: (state) => {
      state.cancelSportBookingBillObj.status = "pending";
    },
    [cancelSportBookingBill.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelSportBookingBillObj.status = "succeeded";
      state.cancelSportBookingBillObj.data = data;
      state.cancelSportBookingBillObj.successMessage = message;
    },
    [cancelSportBookingBill.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelSportBookingBillObj.status = "rejected";
      state.cancelSportBookingBillObj.errorMessage = message;
    },
    [getSportBillViaRemoteBillId.pending]: (state) => {
      state.getSportBillViaRemoteBillIdObj.status = "pending";
    },
    [getSportBillViaRemoteBillId.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSportBillViaRemoteBillIdObj.status = "succeeded";
      state.getSportBillViaRemoteBillIdObj.data = data;
      state.getSportBillViaRemoteBillIdObj.successMessage = message;
    },
    [getSportBillViaRemoteBillId.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSportBillViaRemoteBillIdObj.status = "rejected";
      state.getSportBillViaRemoteBillIdObj.errorMessage = message;
    },
  },
});

export default sportBookingBillSlice.reducer;

// state
export const sportBookingBillSelector = (state) => state.sportBookingBill;
