import { ButtonBase, Grid, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { businessSelector } from "../../../services/business/business-slice.service";
import { sportSelector } from "../../../services/sport/sport-slice.service";

const SectionText = styled(Typography)(() => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

export default function SportCategorySection() {
  const { getBusinessCategoriesObj } = useSelector(sportSelector);
  const navigate = useNavigate();
  const { getBusinessDetailsViaSlugObj } = useSelector(businessSelector);

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <SectionText>Sport Categories</SectionText>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Grid container columnSpacing={2} rowSpacing={1}>
        {getBusinessCategoriesObj.status === "succeeded" &&
          getBusinessCategoriesObj.data.length > 0 &&
          getBusinessCategoriesObj.data.map((item) => (
            <Grid item>
              <ButtonBase
                onClick={() => {
                  navigate(
                    {
                      pathname: routes.BUSINESS_SPORT,
                      search: `?id=${getBusinessDetailsViaSlugObj.data.id}&q=${
                        item.label
                      }&categoryId=${item.id}&date=${dayjs()
                        .add(1, "day")
                        .format("YYYY-MM-DD")}&time=09:00:00&duration=2`,
                    },
                    {
                      state: {
                        categoriesLabel: [item.label],
                      },
                    },
                  );
                }}
                sx={{
                  width: "150px",
                  height: "150px",
                  backgroundImage: `url(${item.imagePath})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: `${theme.shape.borderRadius[2]}px`,
                  cursor: "pointer",
                  opacity: 1,
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography textAlign="center" sx={{ color: theme.palette.colors.text.white }}>
                  {item.label}
                </Typography>
              </ButtonBase>
            </Grid>
          ))}
        {getBusinessCategoriesObj.status === "succeeded" &&
          getBusinessCategoriesObj.data.length === 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center">No sport categories</Typography>
            </Grid>
          )}
        {getBusinessCategoriesObj.status === "pending" && (
          <>
            <Grid item>
              <CustomSkeleton width={150} height={150} />
            </Grid>
            <Grid item>
              <CustomSkeleton width={150} height={150} />
            </Grid>
            <Grid item>
              <CustomSkeleton width={150} height={150} />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
