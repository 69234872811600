import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI } from "../../infrastructure/utils";
import { getReq } from "../api.services";

export const getBusiness = createAsyncThunk("sports/businesses/{id}", async (payload) => {
  const response = await getReq(`${getAPI()}/sports/businesses/${payload}`);
  return response;
});

export const getBusinessCategories = createAsyncThunk(
  "sports/categories/business",
  async (payload) => {
    const response = await getReq(`${getAPI()}/sports/categories?businessId=${payload}`);
    return response;
  },
);

export const getBusinessLayout = createAsyncThunk("sports/layouts/business", async (payload) => {
  const response = await getReq(
    `${getAPI()}/sports/layouts?businessId=${payload.businessId}&q=${payload.q}`,
  );
  return response;
});

export const getCourts = createAsyncThunk("sports/courts", async (payload) => {
  const { businessId, date, q, duration, startTime } = payload;

  const response = await getReq(
    `${getAPI()}/sports?businessId=${businessId}&date=${date}&q=${q}&duration=${duration}&startTime=${startTime}`,
  );
  return response;
});

const sportSlice = createSlice({
  name: "sport",
  initialState: {
    getBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBusinessCategoriesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBusinessLayoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCourtsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getBusiness.pending]: (state) => {
      state.getBusinessObj.status = "pending";
    },
    [getBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessObj.status = "succeeded";
      state.getBusinessObj.data = data;
      state.getBusinessObj.successMessage = message;
    },
    [getBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessObj.status = "failed";
      state.getBusinessObj.errorMessage = message;
    },
    [getBusinessCategories.pending]: (state) => {
      state.getBusinessCategoriesObj.status = "pending";
    },
    [getBusinessCategories.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessCategoriesObj.status = "succeeded";
      state.getBusinessCategoriesObj.data = data;
      state.getBusinessCategoriesObj.successMessage = message;
    },
    [getBusinessCategories.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessCategoriesObj.status = "failed";
      state.getBusinessCategoriesObj.errorMessage = message;
    },
    [getBusinessLayout.pending]: (state) => {
      state.getBusinessLayoutObj.status = "pending";
    },
    [getBusinessLayout.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessLayoutObj.status = "succeeded";
      state.getBusinessLayoutObj.data = data;
      state.getBusinessLayoutObj.successMessage = message;
    },
    [getBusinessLayout.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessLayoutObj.status = "failed";
      state.getBusinessLayoutObj.errorMessage = message;
    },
    [getCourts.pending]: (state) => {
      state.getCourtsObj.status = "pending";
    },
    [getCourts.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCourtsObj.status = "succeeded";
      state.getCourtsObj.data = data;
      state.getCourtsObj.successMessage = message;
    },
    [getCourts.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCourtsObj.status = "failed";
      state.getCourtsObj.errorMessage = message;
    },
  },
});

export default sportSlice.reducer;

// state
export const sportSelector = (state) => state.sport;
