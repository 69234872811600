import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getBusiness,
  getBusinessCategories,
  getBusinessLayout,
  getCourts,
} from "../../../services/sport/sport-slice.service";
import CourtResultList from "../components/court-result-list.component";
import Filters from "../components/filters.component";
import { getLiveAvailability } from "../../../services/sport/booking/sport-booking-slice.service";

const validationSchema = Yup.object().shape({
  q: Yup.string().required().label("q"),
});

const ModalBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  padding: "10px",
  paddingBottom: "40px",
}));

export default function BusinessSportScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const date = new URLSearchParams(location.search).get("date");
  const time = new URLSearchParams(location.search).get("time");
  const duration = new URLSearchParams(location.search).get("duration");
  const q = new URLSearchParams(location.search).get("q");
  const categoryId = parseInt(new URLSearchParams(location.search).get("categoryId"), 10);
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const [showPhoneViewBusinessList, setShowPhoneViewBusinessList] = useState(false);

  const handleSubmitSearch = (values) => {
    if (isMobile) {
      setShowPhoneViewBusinessList(true);
    }

    dispatch(getBusinessLayout({ businessId: id, q })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });

    const payload = { ...values };
    payload.date = format(new Date(payload.date), "yyyy-MM-dd");
    payload.startTime = dayjs(new Date(payload.time)).format("HH:mm:00");
    dispatch(getCourts(payload)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getBusinessDetails = () => {
    dispatch(getBusiness(id)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getBusinessSportCategories = () => {
    dispatch(getBusinessCategories(id)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getSportLiveAvailability = (sportQ, selectedDate) => {
    dispatch(getLiveAvailability({ businessId: id, q: sportQ, date: selectedDate })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBusinessDetails();
    getBusinessSportCategories();
    getSportLiveAvailability(q, date);
    handleSubmitSearch({
      q,
      businessId: id,
      date: dayjs(new Date(date)),
      time: dayjs(new Date(`${date} ${time}`)),
      duration,
    });
  }, []);

  return (
    <Box marginTop={isMobile ? 0 : 3}>
      <PaddedView multiples={2}>
        <Grid item>
          <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size24}>
            Discover the Best Local Sports Activities
          </Typography>
        </Grid>
        <Spacer size="l" />
        <Form
          innerRef={formRef}
          initialValues={{
            q,
            businessId: id,
            date: dayjs(new Date(date)),
            time: dayjs(new Date(`${date} ${time}`)),
            duration: parseInt(duration, 10),
            categoriesId: [categoryId],
            tempCategoriesId: [categoryId],
            sportCategoryId: categoryId,
            tempSportCategoryId: categoryId,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSearch}
        >
          <Grid container columnSpacing={6}>
            <Grid item xs={isMobile ? 12 : 5}>
              <Filters getSportLiveAvailability={getSportLiveAvailability} />
            </Grid>
            {!isMobile && (
              <Grid item xs={7}>
                <CourtResultList setShowModal={setShowPhoneViewBusinessList} />
              </Grid>
            )}
            <Modal open={showPhoneViewBusinessList}>
              <ModalBox>
                <Grid item xs={12}>
                  <CustomButton
                    width="auto"
                    variant="text"
                    fontSize={theme.fonts.fontSizes.size15}
                    onClick={() => {
                      setShowPhoneViewBusinessList(false);
                    }}
                  >
                    {"< Back"}
                  </CustomButton>
                </Grid>
                <CourtResultList setShowModal={setShowPhoneViewBusinessList} />
              </ModalBox>
            </Modal>
          </Grid>
        </Form>
      </PaddedView>
    </Box>
  );
}
