import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExploreEvent from "../../../assets/images/explore-event.png";
import ExploreFitness from "../../../assets/images/explore-fitness.png";
import ExploreHealth from "../../../assets/images/explore-health.png";
import ExploreWellness from "../../../assets/images/explore-wellness.png";
import routes from "../../../components/navigation/routes";
import { ReactComponent as Pickleball } from "../../../assets/images/Pickleball-icon.svg";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.colors.brand.primary,
  // fontSize: "15px",
}));

function ExploreSection() {
  const theme = useTheme();
  // const isTabletHorizontal = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box sx={{ display: "flex" }} width="800px">
        <Box
          item
          sx={{ width: "192px", maxHeight: "200px", cursor: "pointer", paddingRight: "10px" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=fitness`)}
        >
          <Grid
            container
            sx={{
              width: "160px",
              backgroundImage: "linear-gradient(#d9e2b1, #ccdc82)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "100%",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Fitness near you</StyledTypography>
            </Grid>
            <Grid item display="flex" justifyContent="center">
              <img width="80%" alt="imgs" src={ExploreFitness} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Box>
        <Box
          item
          sx={{ width: "192px", maxHeight: "200px", cursor: "pointer", paddingRight: "10px" }}
          onClick={() => navigate(`${routes.EXPLORE_SPORT}`)}
        >
          <Grid
            container
            sx={{
              width: "160px",
              backgroundImage: "linear-gradient(#FFF1D4, #FFD883)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "100%",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Sports near you</StyledTypography>
            </Grid>
            <Grid item display="flex" justifyContent="center" xs={12}>
              <Pickleball width="80%" style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Box>
        <Box
          item
          sx={{ width: "192px", maxHeight: "200px", cursor: "pointer", paddingRight: "10px" }}
          onClick={() => navigate(`${routes.EXPLORE}?searchFor=event`)}
        >
          <Grid
            container
            sx={{
              width: "160px",
              backgroundImage: "linear-gradient(#d9e2b1, #ccdc82)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "200px",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Events near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreEvent} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Box>
        <Box
          item
          sx={{ width: "192px", maxHeight: "200px", cursor: "pointer", paddingRight: "10px" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=wellness`)}
        >
          <Grid
            container
            sx={{
              width: "160px",
              backgroundImage: "linear-gradient(#FFF1D4, #FFD883)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "200px",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Wellness near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreWellness} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Box>
        <Box
          item
          sx={{ width: "192px", maxHeight: "200px", cursor: "pointer", paddingRight: "10px" }}
          onClick={() => navigate(`${routes.EXPLORE}?tag=health`)}
        >
          <Grid
            container
            sx={{
              width: "160px",
              backgroundImage: "linear-gradient(#d9e2b1, #ccdc82)",
              borderRadius: `${theme.shape.borderRadius[2]}px`,
              height: "100%",
              paddingY: "20px",
            }}
          >
            <Grid item xs={12}>
              <StyledTypography textAlign="center">Health near you</StyledTypography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="center"
              xs={12}
              height="90%"
              paddingTop="10px"
            >
              <img width="80%" alt="imgs" src={ExploreHealth} style={{ objectFit: "contain" }} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ExploreSection;
