import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import { useSelector } from "react-redux";
import FormAddressAutoComplete from "../../../../components/forms/form-address-auto-complete.component";
import FormCalendarPickerDate from "../../../../components/forms/form-calendar-picker-date.component";
import FormPickerTime from "../../../../components/forms/form-picker-time.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { searchSportSelector } from "../../../../services/search/sport/search-sport-slice.service";
import CategoryFilter from "../category-filter.component";

function Filters() {
  const theme = useTheme();
  const [formValid, setIsFormValid] = useState(false);
  const { getBusinessesObj } = useSelector(searchSportSelector);
  const { values, setFieldValue, isValid, validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
    if (setIsFormValid) {
      setIsFormValid(isValid);
    }
  }, [values, isValid]);

  return (
    <Grid container rowSpacing={2} sx={{ position: "relative" }}>
      <Grid item xs={12}>
        <Typography>Type of Sport</Typography>
        <Typography color="#666" variant="subtitle2">
          Please select the sport you would like to book
        </Typography>
        <CategoryFilter searchFor="sport" />
      </Grid>
      <Grid item xs={12}>
        <Typography>Venue Location</Typography>
        <FormAddressAutoComplete name="location" submitOnChange={false} />
      </Grid>
      <Grid item xs={12}>
        <Typography>Date</Typography>
        <FormCalendarPickerDate name="date" disablePast />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "grid",
          "& .MuiTextField-root": {
            justifySelf: "center",
          },
          "& .MuiInputBase-root": {
            borderRadius: "10px",
          },
        }}
      >
        <Typography>Time</Typography>
        <FormPickerTime name="time" />
      </Grid>
      <Grid item xs={12}>
        <Typography>Duration</Typography>
        <Grid container alignItems="center" columnSpacing={2} justifyContent="center">
          <Grid item>
            <IconButton
              color="primary"
              sx={{ border: "1.5px solid" }}
              onClick={() => {
                if (values.duration - 0.5 >= 1) {
                  setFieldValue("duration", values.duration - 0.5);
                }
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: "1.5px solid",
                padding: "15px",
                borderRadius: "10px",
                borderColor: theme.palette.colors.brand.primary,
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary, fontWeight: "bold" }}>
                {values.duration} Hours
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              sx={{ border: "1.5px solid" }}
              onClick={() => {
                if (values.duration + 0.5 <= 6) {
                  setFieldValue("duration", values.duration + 0.5);
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
        <FormSubmitButton
          isLoading={getBusinessesObj.status === "pending"}
          width={300}
          borderRadius={3}
          disabled={!formValid || !(values.lat || values.long)}
        >
          <Typography>Search Venues</Typography>
        </FormSubmitButton>
      </Grid>
    </Grid>
  );
}

export default Filters;
