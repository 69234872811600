import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import { StaticDatePicker } from "@mui/x-date-pickers";

function FormCalendarPickerDate({ name, disabled, disablePast, submitOnChange }) {
  const { setFieldValue, values, touched, errors, handleSubmit } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, format(date, "yyyy-MM-dd"));

    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          disablePast={disablePast}
          disabled={disabled}
          value={values[name]}
          onChange={(date) => {
            handleChange(date);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormCalendarPickerDate.defaultProps = {
  disabled: false,
  disablePast: true,
  submitOnChange: false,
};

FormCalendarPickerDate.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

export default FormCalendarPickerDate;
