import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Grid,
  IconButton,
  Link,
  Rating,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import GoogleMapReact from "google-map-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as MerchantLogoDefault } from "../../../assets/images/merchant-logo-default.svg";
import CustomButton from "../../../components/button/custom-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../components/notification/unverified-user-modal.component";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import ImageCarousel from "../../../components/utils/image-carousel.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { isLogin } from "../../../infrastructure/utils";
import {
  businessSelector,
  getBusinessDetailsViaSlug,
} from "../../../services/business/business-slice.service";
import { getUserExclusiveReferralStatus } from "../../../services/exclusive-referral/exclusive-referral-slice.service";
import { getAvailableHourlyBooking } from "../../../services/hourly-booking/hourly-booking-slice.service";
import { checkUserMembership } from "../../../services/membership/membership-slice.service";
import { getPackagePlans } from "../../../services/package/plan/package-plan-slice.service";
import { getSubscriptionPlans } from "../../../services/subscription/plan/subscription-plan-slice.service";
import AmenitySection from "../components/amenity-section.component";
import AvailableSessionModal from "../components/available-session-modal.component";
import {
  ExclusiveReferralCodeInputModal,
  ExclusiveReferralModal,
} from "../components/exclusive-referral-modal.component";
import FitnessClassSection from "../components/fitness-class-section.component";
import MembershipSection from "../components/membership-section.component";
import PackageSection from "../components/package-section.component";
import { RequireMembershipModal } from "../components/require-membership-modal.component";
import ReviewSection from "../components/review-section.component";
import SubscriptionSection from "../components/subscription-section.component";
import UpcomingEventSection from "../components/upcoming-event-section.component";
import BusinessScreenLoader from "../loader/business-screen-loader.component";
import SportCategorySection from "../components/sport-category-section.component";
import { getBusinessCategories } from "../../../services/sport/sport-slice.service";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

function Marker(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

const availableSessionValidationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
  slots: Yup.array()
    .of(
      Yup.object().shape({
        startAt: Yup.date().required(),
        endAt: Yup.date().required(),
      }),
    )
    .required()
    .label("Slots"),
});

function BusinessScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { getBusinessDetailsViaSlugObj } = useSelector(businessSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [exclusiveReferralValidation, setExclusiveReferralValidation] = useState(false);
  const [requireMembershipValidation, setRequireMembershipValidation] = useState(false);
  const [showExclusiveReferralWarningModal, setShowExclusiveReferralWarningModal] = useState(false);
  const [showRequireMembershipWarningModal, setShowRequireMembershipWarningModal] = useState(false);
  const [showExclusiveReferralCodeInputModal, setShowExclusiveReferralCodeInputModal] =
    useState(false);
  const [shareExclusiveCode, setShareExclusiveCode] = useState(null);
  const [clipboardSnackbar, setClipboardSnackbar] = useState(false);
  const availableSessionFormRef = useRef();
  const [businessReady, setBusinessReady] = useState(false);

  const handleClickHourlyBooking = () => {
    if (exclusiveReferralValidation) {
      setShowExclusiveReferralWarningModal(true);
    } else {
      setShowSessionModal(true);
    }
  };

  const getAvailableSession = (values) => {
    dispatch(getAvailableHourlyBooking(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getSubscriptionPlan = (values) => {
    dispatch(getSubscriptionPlans(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getPackagePlan = (values) => {
    dispatch(getPackagePlans(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getSportCategories = (businessId) => {
    dispatch(getBusinessCategories(businessId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getExclusiveReferralStatus = (values) => {
    dispatch(getUserExclusiveReferralStatus(values)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        const code = payload.data?.code ?? null;
        setShareExclusiveCode(code);
      }

      if (meta.requestStatus === "rejected") {
        setExclusiveReferralValidation(true);
      }
      setBusinessReady(true);
    });
  };

  useEffect(() => {
    if (clipboardSnackbar) {
      createSnackBar({
        message: "Exclusive code copied to clipboard",
        type: "success",
        open: true,
      });
      setClipboardSnackbar(false);
    }
  }, [clipboardSnackbar]);

  useEffect(() => {
    dispatch(getBusinessDetailsViaSlug(slug)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (payload.data.requireMembership) {
          if (isLogin()) {
            dispatch(checkUserMembership({ businessId: payload.data.id })).then((res) => {
              if (res.meta.requestStatus === "rejected") {
                setRequireMembershipValidation(true);
              }
              setBusinessReady(true);
            });
          } else {
            setRequireMembershipValidation(true);
            setBusinessReady(true);
          }
        } else {
          setBusinessReady(true);
        }
        if (payload.data.hasHourlyBookings) {
          getAvailableSession({
            businessId: payload.data.id,
            date: format(new Date(), "yyyy-MM-dd"),
          });
        }
        if (payload.data.hasSubscriptionPlans) {
          getSubscriptionPlan({
            businessId: payload.data.id,
            page: 1,
          });
        }
        if (payload.data.hasPackagePlans) {
          getPackagePlan({
            businessId: payload.data.id,
            page: 1,
          });
        }
        if (payload.data.exclusiveReferrals) {
          if (isLogin()) {
            getExclusiveReferralStatus({ businessId: payload.data.id });
          } else {
            setExclusiveReferralValidation(true);
            setBusinessReady(true);
          }
        }
        getSportCategories(payload.data.id);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <PaddedView multiples={2}>
      <Spacer size="xl" />
      {businessReady ? (
        <Grid container>
          {showSessionModal && getBusinessDetailsViaSlugObj.data.requireVerification && (
            <UnverifiedUserModal cancelAction={() => setShowSessionModal(false)} />
          )}
          <Grid container columnSpacing={2}>
            <Grid item xs={isMobile ? 12 : 4}>
              <ImageCarousel imageList={getBusinessDetailsViaSlugObj.data.images} />
            </Grid>
            {!isMobile && (
              <Grid item marginX="15px">
                {getBusinessDetailsViaSlugObj.data.merchantLogo ? (
                  <img
                    width="100px"
                    height="100px"
                    src={getBusinessDetailsViaSlugObj.data.merchantLogo}
                    alt="merchant_logo"
                  />
                ) : (
                  <MerchantLogoDefault width={100} height={100} />
                )}
              </Grid>
            )}

            <Grid item xs={isMobile ? 12 : 6} display="flex">
              <Grid container columnSpacing={1}>
                {isMobile && (
                  <Grid item xs={2}>
                    {getBusinessDetailsViaSlugObj.data.merchantLogo ? (
                      <img
                        width="50px"
                        height="50px"
                        src={getBusinessDetailsViaSlugObj.data.merchantLogo}
                        alt="merchant_logo"
                      />
                    ) : (
                      <MerchantLogoDefault width={50} height={50} sx={{ paddingRight: "20px" }} />
                    )}
                  </Grid>
                )}

                <Grid
                  item
                  display="grid"
                  flexDirection="column"
                  alignContent="space-between"
                  height="98%"
                  xs={10}
                >
                  <Grid item>
                    <Grid item>
                      <Typography
                        fontWeight="bold"
                        fontSize={
                          isMobile ? theme.fonts.fontSizes.size18 : theme.fonts.fontSizes.size24
                        }
                      >
                        {getBusinessDetailsViaSlugObj.data.name}
                      </Typography>
                    </Grid>
                    <Spacer />
                    <Grid item display="flex" alignContent="center">
                      <PlaceIcon color="primary" />
                      <Spacer position="left" />
                      <Typography>{getBusinessDetailsViaSlugObj.data.city}</Typography>
                    </Grid>
                    <Spacer />
                    <Grid item display="flex" alignContent="center">
                      <CallIcon color="primary" />
                      <Spacer position="left" />
                      <Typography>{getBusinessDetailsViaSlugObj.data.contactNo}</Typography>
                    </Grid>
                    <Spacer />
                    <Grid item container>
                      <Rating
                        value={parseFloat(getBusinessDetailsViaSlugObj.data.reviews.averageRatings)}
                        readOnly
                        sx={{ color: theme.palette.colors.brand.primary }}
                      />
                      <Spacer position="left" />
                      <Typography>
                        {`(${getBusinessDetailsViaSlugObj.data.reviews.totalRatings})`}
                      </Typography>
                    </Grid>
                  </Grid>

                  {shareExclusiveCode !== null && (
                    <Grid item container spacing={1} paddingTop="10px">
                      <Tooltip title="Copy" placement="bottom" arrow>
                        <Grid item xs={isMobile ? 12 : 5}>
                          <CustomButton
                            onClick={() => {
                              navigator.clipboard.writeText(shareExclusiveCode);
                              setClipboardSnackbar(true);
                            }}
                            fontSize={theme.fonts.fontSizes.size16}
                          >
                            Share Your Code <br />
                            <span style={{ fontSize: theme.fonts.fontSizes.size20 }}>
                              {shareExclusiveCode}
                            </span>
                          </CustomButton>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  )}

                  <Grid item container spacing={1}>
                    {getBusinessDetailsViaSlugObj.data.hasHourlyBookings && (
                      <Grid item xs={isMobile ? 12 : 5}>
                        <ExclusiveReferralModal
                          showModal={showExclusiveReferralWarningModal}
                          setShowModal={setShowExclusiveReferralWarningModal}
                          setShowCodeInputModal={setShowExclusiveReferralCodeInputModal}
                        />
                        <RequireMembershipModal
                          showModal={showRequireMembershipWarningModal}
                          setShowModal={setShowRequireMembershipWarningModal}
                        />
                        <Form
                          initialValues={{
                            exclusiveReferralCode: null,
                          }}
                        >
                          <ExclusiveReferralCodeInputModal
                            showCodeInputModal={showExclusiveReferralCodeInputModal}
                            setShowCodeInputModal={setShowExclusiveReferralCodeInputModal}
                            setShowSessionModal={setShowSessionModal}
                          />
                        </Form>

                        <Form
                          innerRef={availableSessionFormRef}
                          initialValues={{
                            businessId: getBusinessDetailsViaSlugObj.data.id,
                            date: format(new Date(), "yyyy-MM-dd"),
                            slots: [],
                            exclusiveReferralCode: null,
                          }}
                          validationSchema={availableSessionValidationSchema}
                          onSubmit={getAvailableSession}
                        >
                          <AvailableSessionModal
                            showModal={showSessionModal}
                            setShowModal={setShowSessionModal}
                          />
                        </Form>

                        <Spacer size="m" />
                        <CustomButton
                          fontSize={theme.fonts.fontSizes.size16}
                          width="100%"
                          onClick={handleClickHourlyBooking}
                        >
                          Book / Walk-In
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <HorizontalDivider spacerSize="l" />

          <Grid item>
            <Grid item>
              <SectionText>About Us</SectionText>
            </Grid>
            <Spacer size="m" />
            <Grid item>
              <Typography textAlign="justify">
                {getBusinessDetailsViaSlugObj.data.description}
              </Typography>
            </Grid>
          </Grid>
          <HorizontalDivider spacerSize="l" />

          <Grid item xs={12}>
            <Grid item>
              <SectionText>Location</SectionText>
            </Grid>
            <Spacer size="m" />
            <Grid item>
              <div style={{ height: "40vh", width: "100%" }}>
                <GoogleMapReact
                  defaultCenter={{
                    lat: parseFloat(getBusinessDetailsViaSlugObj.data.location.lat),
                    lng: parseFloat(getBusinessDetailsViaSlugObj.data.location.long),
                  }}
                  defaultZoom={17}
                >
                  <Marker
                    lat={parseFloat(getBusinessDetailsViaSlugObj.data.location.lat)}
                    lng={parseFloat(getBusinessDetailsViaSlugObj.data.location.long)}
                    text={getBusinessDetailsViaSlugObj.data.name}
                  />
                </GoogleMapReact>
              </div>
            </Grid>
            <Spacer size="m" />
            <Grid item>
              <Typography textAlign="justify">
                {getBusinessDetailsViaSlugObj.data.location.address}
              </Typography>
            </Grid>
          </Grid>

          <HorizontalDivider spacerSize="l" />
          <SportCategorySection />

          <HorizontalDivider spacerSize="l" />
          <FitnessClassSection />

          <HorizontalDivider spacerSize="l" />
          <UpcomingEventSection />

          <HorizontalDivider spacerSize="l" />
          <MembershipSection />

          {getBusinessDetailsViaSlugObj.data.hasSubscriptionPlans && (
            <>
              <HorizontalDivider spacerSize="l" />
              <SubscriptionSection requireMembershipValidation={requireMembershipValidation} />
            </>
          )}

          {getBusinessDetailsViaSlugObj.data.hasPackagePlans && (
            <>
              <HorizontalDivider spacerSize="l" />
              <PackageSection requireMembershipValidation={requireMembershipValidation} />
            </>
          )}

          <HorizontalDivider spacerSize="l" />
          <AmenitySection />
          <HorizontalDivider spacerSize="l" />

          <ReviewSection />
        </Grid>
      ) : (
        <BusinessScreenLoader />
      )}
    </PaddedView>
  );
}

export default BusinessScreen;
