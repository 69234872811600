import { Box, Grid, Pagination, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import { searchSportSelector } from "../../../../services/search/sport/search-sport-slice.service";
import BusinessResultCard from "./business-result-card.component";

export default function SportBusinessResultList({ onPageChange, page }) {
  const { getBusinessesObj } = useSelector(searchSportSelector);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", paddingBottom: "10px", textAlign: "center" }}
        >
          Venues
        </Typography>
      </Grid>

      {getBusinessesObj.status === "idle" && (
        <Box sx={{ height: "60vh", alignContent: "center", textAlign: "center", width: "100%" }}>
          Please select a sport and location.
        </Box>
      )}
      {getBusinessesObj.status === "pending" && (
        <Grid item container rowSpacing={1} xs={12}>
          <Grid item xs={12}>
            <CustomSkeleton width="100%" height="200px" />
          </Grid>
          <Grid item xs={12}>
            <CustomSkeleton width="100%" height="200px" />
          </Grid>
          <Grid item xs={12}>
            <CustomSkeleton width="100%" height="200px" />
          </Grid>
        </Grid>
      )}
      {getBusinessesObj.status === "succeeded" && (
        <Grid item container rowSpacing={1} xs={12}>
          {getBusinessesObj.data.items.map((item) => (
            <Grid item xs={12}>
              <BusinessResultCard itemDetails={item} />
            </Grid>
          ))}
          <Grid item xs={12} display="flex" justifyContent="center">
            <Pagination
              page={page}
              onChange={onPageChange}
              count={getBusinessesObj.data.pagination.totalPages}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
      {getBusinessesObj.status === "succeeded" && getBusinessesObj.data.items.length === 0 && (
        <Box sx={{ height: "60vh", alignContent: "center", textAlign: "center", width: "100%" }}>
          No results found.
        </Box>
      )}
    </Grid>
  );
}

SportBusinessResultList.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
