import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../../api.services";

export const previewPackageHourlyBooking = createAsyncThunk(
  "packages/hourly_bookings/preview",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/hourly_bookings/preview`,
      payload,
    );
    return response;
  },
);

export const createPackageHourlyBooking = createAsyncThunk(
  "packages/hourly_bookings",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/packages/hourly_bookings`,
      payload,
    );
    return response;
  },
);

const packageHourlyBookingSlice = createSlice({
  name: "packageHourlyBooking",
  initialState: {
    previewPackageHourlyBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackageHourlyBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewPackageHourlyBooking.pending]: (state) => {
      state.previewPackageHourlyBookingObj.status = "pending";
    },
    [previewPackageHourlyBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewPackageHourlyBookingObj.status = "succeeded";
      state.previewPackageHourlyBookingObj.data = data;
      state.previewPackageHourlyBookingObj.successMessage = message;
    },
    [previewPackageHourlyBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewPackageHourlyBookingObj.status = "failed";
      state.previewPackageHourlyBookingObj.errorMessage = message;
    },
    [createPackageHourlyBooking.pending]: (state) => {
      state.createPackageHourlyBookingObj.status = "pending";
    },
    [createPackageHourlyBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackageHourlyBookingObj.status = "succeeded";
      state.createPackageHourlyBookingObj.data = data;
      state.createPackageHourlyBookingObj.successMessage = message;
    },
    [createPackageHourlyBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackageHourlyBookingObj.status = "failed";
      state.createPackageHourlyBookingObj.errorMessage = message;
    },
  },
});

export default packageHourlyBookingSlice.reducer;

// state
export const packageHourlyBookingSelector = (state) => state.packageHourlyBooking;
