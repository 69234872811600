import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
// import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/button/custom-button.component";
import routes from "../../../../components/navigation/routes";
import DialogBox from "../../../../components/notification/dialog-box.component";
import UnverifiedUserModal from "../../../../components/notification/unverified-user-modal.component";
import Spacer from "../../../../components/utils/spacer.component";
import { bookingSelector } from "../../../../services/booking/booking-slice.service";
import { fitnessClassSessionBookingSelector } from "../../../../services/fitness_class/session/booking/fitness-class-session-booking-slice.service";
import PackageDetailsCard from "../package/package-details-card.component";
import { appointmentSelector } from "../../../../services/appointment/appointment-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "auto",
  overflowY: "auto",
  outline: "none",
}));

const MarkerBox = styled(Grid)({
  width: "100px",
  height: "50px",
  flexDirection: "row",
});

const StyledIcon = styled(IconButton)({
  width: "30px",
  height: "30px",
  color: "red",
});

const StyledTypography = styled(Link)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
  backgroundColor: theme.palette.colors.brand.secondary,
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textAlign: "center",
  marginTop: "-10px",
  borderRadius: theme.shape.borderRadius[0],
  paddingLeft: "5px",
  paddingRight: "5px",
}));

const StyledLocationOnIcon = styled(LocationOnIcon)({
  width: "30px",
  height: "30px",
});

function Marker(mapProps) {
  const { text, lat, lng } = mapProps;
  return (
    <MarkerBox container>
      <Grid item xs={3}>
        <StyledIcon
          onClick={() =>
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`, "_blank")
          }
        >
          <StyledLocationOnIcon />
        </StyledIcon>
      </Grid>
      <Grid item xs={9}>
        <StyledTypography
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
        >
          {text}
        </StyledTypography>
      </Grid>
    </MarkerBox>
  );
}

function BookingDetailsModal({
  itemDetails,
  showModal,
  setShowModal,
  cancelBooking,
  cancelMerchantRequest,
  rejectAppointmentRequest,
  acceptRequestedAppointment,
  setShowEditSessionModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { cancelBookingSlotObj } = useSelector(bookingSelector);
  const { rejectRequestFitnessClassBookingObj } = useSelector(fitnessClassSessionBookingSelector);
  const { payRequestedAppointmentObj, rejectAppointmentObj } = useSelector(appointmentSelector);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmAppointmentModal, setShowConfirmAppointmentModal] = useState(false);
  const navigate = useNavigate();
  const isPackageRedeemableOnly =
    itemDetails?.booking.status === "merchant_request" &&
    itemDetails?.booking.session.requestedPackage &&
    itemDetails?.booking.session.requestedPackage.id !== null;

  const showNextDay = (startAt, endAt) => {
    if (!startAt || !endAt) {
      return false;
    }
    const startDate = new Date(startAt);
    const endDate = new Date(endAt);

    // Compare the two dates
    if (startDate.getDate() !== endDate.getDate()) {
      return true;
    }

    return false;
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        {itemDetails && (
          <>
            {itemDetails?.booking.status === "merchant_request" &&
              itemDetails.booking?.location?.requireVerification && (
                <UnverifiedUserModal cancelAction={() => setShowModal(false)} />
              )}
            <DialogBox
              title={
                itemDetails.booking.status === "merchant_request"
                  ? "Booking Request"
                  : "Confirm cancellation?"
              }
              isShow={showConfirmModal}
              setIsShowAlert={setShowConfirmModal}
              message={
                <Grid item>
                  <Typography sx={{ textAlign: "center" }}>
                    {itemDetails.booking.status === "merchant_request"
                      ? "Would you like to reject this booking request?"
                      : "This will be refunded to your credit wallet."}
                  </Typography>
                </Grid>
              }
              isConfirmHandle={() => {
                setShowConfirmModal(false);
                if (itemDetails.booking.status === "merchant_request") {
                  if (itemDetails.booking.type === "merchant_appointment") {
                    rejectAppointmentRequest(itemDetails.id);
                  } else {
                    cancelMerchantRequest(itemDetails.id);
                  }
                } else {
                  cancelBooking(itemDetails.id);
                }
              }}
              buttonText={itemDetails.booking.status === "merchant_request" ? "Confirm" : "Ok"}
            />
            <DialogBox
              title="Booking is non-refundable"
              isShow={showConfirmAppointmentModal}
              setIsShowAlert={setShowConfirmAppointmentModal}
              message={
                <Grid item>
                  <Typography sx={{ textAlign: "center" }}>
                    By redeeming with your package code, you will not be able to cancel or
                    reschedule this booking in the future.
                  </Typography>
                </Grid>
              }
              isConfirmHandle={() => {
                setShowConfirmAppointmentModal(false);
                acceptRequestedAppointment(
                  itemDetails.id,
                  itemDetails.booking.session.requestedPackage.code,
                );
              }}
              buttonText="Confirm"
            />
            <Grid
              item
              display="grid"
              flexDirection="column"
              alignContent="space-between"
              height="100%"
              xs={12}
              padding="30px"
            >
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography textTransform="uppercase" fontSize={theme.fonts.fontSizes.size14}>
                      {(itemDetails.booking.type === "gym_session" ||
                        itemDetails.booking.type === "merchant_hourly_booking") &&
                        "gym session"}
                      {(itemDetails.booking.type === "merchant_class_session" ||
                        itemDetails.booking.type === "merchant_appointment") &&
                        itemDetails.booking.session.class.category.label}
                      {itemDetails.booking.type === "merchant_event_attendee" && "EVENT"}
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      fontSize={theme.fonts.fontSizes.size20}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {(itemDetails.booking.type === "gym_session" ||
                        itemDetails.booking.type === "merchant_hourly_booking") &&
                        itemDetails.booking.location.name}
                      {(itemDetails.booking.type === "merchant_class_session" ||
                        itemDetails.booking.type === "merchant_appointment") &&
                        itemDetails.booking.session.class.name}
                      {itemDetails.booking.type === "merchant_event_attendee" &&
                        (itemDetails.booking.session.title
                          ? itemDetails.booking.session.title
                          : itemDetails.booking.session.event.title)}
                    </Typography>

                    {itemDetails.booking.type !== "gym_session" &&
                      itemDetails.booking.type !== "merchant_hourly_booking" && (
                        <Typography fontSize={theme.fonts.fontSizes.size20}>
                          {itemDetails.booking.location.name}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item position="absolute" right={10}>
                    <IconButton onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Spacer size="xl" />
                <Box
                  sx={{
                    overflowY: "auto",
                    height: !isMobile ? "300px" : "auto",
                    paddingX: isMobile ? "10px" : "20px",
                  }}
                >
                  {/* {itemDetails.booking.type === "merchant_event_attendee" && (
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={itemDetails.booking.publicId}
                        viewBox="0 0 256 256"
                      />
                      <Spacer size="xl" />
                    </div>
                  )} */}
                  <Grid container flexDirection="column" rowSpacing={2}>
                    {itemDetails.booking.type === "merchant_event_attendee" &&
                      itemDetails.booking.session.sessionCategories.length > 0 && (
                        <Grid item xs={12}>
                          <Grid item>
                            <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                              Selected Categories
                            </Typography>
                          </Grid>
                          <Spacer />
                          {itemDetails.booking.session.sessionCategories.map((item) => (
                            <Grid item key={item.id}>
                              <Grid item container justifyContent="space-between">
                                <Grid item>
                                  <Typography fontWeight={600}>{item.label}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="primary" fontWeight="bold">
                                    RM {item.price}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item container justifyContent="space-between">
                                <Grid item>
                                  <Typography sx={{ color: theme.palette.colors.text.tertiary }}>
                                    {item.date}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography sx={{ color: theme.palette.colors.text.tertiary }}>
                                  {item.time}
                                </Typography>
                              </Grid>
                              <Spacer />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                          Booking Details
                        </Typography>
                      </Grid>
                      {(itemDetails.booking.type === "gym_session" ||
                        itemDetails.booking.type === "merchant_hourly_booking") &&
                        itemDetails.editable && (
                          <Grid
                            item
                            sx={{
                              cursor: "pointer",
                              color: theme.palette.colors.brand.primary,
                            }}
                            onClick={() => setShowEditSessionModal(true)}
                          >
                            <Typography fontWeight="bold">Edit</Typography>
                          </Grid>
                        )}
                    </Grid>

                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography>Booking ID</Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">{itemDetails.id}</Typography>
                      </Grid>
                    </Grid>
                    {itemDetails.booking.type === "merchant_sport_booking" && (
                      <Grid item container justifyContent="space-between">
                        <Grid item>
                          <Typography fontWeight="bold" color="primary">
                            {itemDetails.booking.session.category}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography>Date</Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          {format(new Date(itemDetails.booking.date), "d MMM yyyy, E")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography>Time</Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          {itemDetails.booking.type !== "merchant_class_session" &&
                          itemDetails.booking.type !== "merchant_appointment"
                            ? `${itemDetails.booking.time} ${
                                showNextDay(itemDetails.booking.startAt, itemDetails.booking.endAt)
                                  ? "(next day)"
                                  : ""
                              }`
                            : `${itemDetails.booking.session.startTime} (${itemDetails.booking.session.durationMins}mins)`}
                        </Typography>
                      </Grid>
                    </Grid>
                    {itemDetails.booking.type === "merchant_sport_booking" && (
                      <>
                        <Grid item>
                          <Typography>Court(s)</Typography>
                        </Grid>
                        {itemDetails.booking.location.courts.map((item) => (
                          <Grid item container justifyContent="space-between">
                            <Grid item>
                              <Typography>{item.label}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>RM {item.price}</Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                    {itemDetails.booking.type === "merchant_class_session" && (
                      <Grid item container justifyContent="space-between">
                        <Grid item>
                          <Typography>Staff</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {itemDetails.booking.session.staff.firstName}{" "}
                            {itemDetails.booking.session.staff.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Spacer size="xl" />
                  {itemDetails.booking.package.code ? (
                    <Grid item>
                      <Typography>
                        This session has been purchased using package code{" "}
                        <span style={{ color: theme.palette.colors.brand.primary }}>
                          {itemDetails.booking.package.code}
                        </span>
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid container flexDirection="column" rowSpacing={2}>
                        <Grid item>
                          <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                            Payment Details
                          </Typography>
                        </Grid>
                        {!isPackageRedeemableOnly && itemDetails.booking.paymentTransaction && (
                          <Grid
                            item
                            container
                            display="flex"
                            justifyContent="space-between"
                            rowSpacing={1}
                          >
                            <Grid item xs={6}>
                              <Typography>Subtotal</Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <Typography fontWeight="bold">
                                RM {itemDetails.booking.paymentTransaction.subtotal}
                              </Typography>
                            </Grid>
                            {itemDetails.booking.paymentTransaction.membershipDiscount !==
                              "0.00" && (
                              <>
                                <Grid item xs={6}>
                                  <Typography>Membership Discount</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography fontWeight="bold">
                                    - RM {itemDetails.booking.paymentTransaction.membershipDiscount}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                            {itemDetails.booking.paymentTransaction.voucherDiscount !== "0.00" && (
                              <>
                                <Grid item xs={6}>
                                  <Typography>Voucher Discount</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <Typography fontWeight="bold">
                                    - RM {itemDetails.booking.paymentTransaction.voucherDiscount}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={6}>
                              <Typography>Total Price</Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <Typography fontWeight="bold">
                                RM {itemDetails.booking.price}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item container justifyContent="space-between">
                          <Grid item>
                            <Typography>Payment Method</Typography>
                          </Grid>
                          <Grid item>
                            <Typography fontWeight="bold">
                              {itemDetails.booking.paymentMethod === "credits" && "RM"}{" "}
                              {itemDetails.booking.paymentMethod
                                ? itemDetails.booking.paymentMethod
                                : "Package Redemption"}
                            </Typography>
                          </Grid>
                          {isPackageRedeemableOnly && (
                            <Grid item>
                              <Spacer size="m" />
                              <Typography>Requested to redeem with:</Typography>
                              <Spacer size="m" />
                              <PackageDetailsCard
                                itemDetails={itemDetails.booking.session.requestedPackage}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Spacer size="m" />
                    </>
                  )}

                  {itemDetails.booking.session.questionResponses &&
                    itemDetails.booking.session.questionResponses.length !== 0 && (
                      <Grid container flexDirection="column" rowSpacing={2}>
                        <Spacer size="l" />
                        <Grid item>
                          <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                            Questions & Responses
                          </Typography>
                        </Grid>
                        {itemDetails.booking.session.questionResponses.map((questionResponse) => (
                          <Grid
                            item
                            container
                            flexDirection="column"
                            key={`${questionResponse.id}-${questionResponse.question}`}
                          >
                            <Grid item display="flex">
                              <Typography fontWeight="bold">Q: </Typography>
                              <Spacer size="s" position="left" />
                              <Typography>{questionResponse.question}</Typography>
                            </Grid>
                            <Grid item display="flex">
                              <Typography fontWeight="bold">R: </Typography>
                              <Spacer size="s" position="left" />
                              <Typography>
                                {questionResponse.open_ended
                                  ? questionResponse.answer
                                  : questionResponse.response}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}

                  <Grid item>
                    <Spacer size="xl" />
                    <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size18}>
                      Location
                    </Typography>
                    <Spacer size="m" />
                    <Grid item>
                      <div style={{ height: isMobile ? "30vh" : "40vh", width: "100%" }}>
                        <GoogleMapReact
                          defaultCenter={{
                            lat: parseFloat(itemDetails.booking.location.lat),
                            lng: parseFloat(itemDetails.booking.location.long),
                          }}
                          defaultZoom={17}
                        >
                          <Marker
                            lat={parseFloat(itemDetails.booking.location.lat)}
                            lng={parseFloat(itemDetails.booking.location.long)}
                            text={itemDetails.booking.location.name}
                          />
                        </GoogleMapReact>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {itemDetails.booking.status === "merchant_request" && (
                <Grid
                  item
                  container
                  paddingTop="30px"
                  paddingBottom={isMobile ? "30px" : "0px"}
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <CustomButton
                      buttonColor="red"
                      textColor={theme.palette.colors.brand.white}
                      isLoading={
                        rejectRequestFitnessClassBookingObj.status === "pending" ||
                        payRequestedAppointmentObj.status === "pending" ||
                        rejectAppointmentObj.status === "pending"
                      }
                      fontSize={theme.fonts.fontSizes.size16}
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      Reject
                    </CustomButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomButton
                      isLoading={
                        rejectRequestFitnessClassBookingObj.status === "pending" ||
                        payRequestedAppointmentObj.status === "pending" ||
                        rejectAppointmentObj.status === "pending"
                      }
                      fontSize={theme.fonts.fontSizes.size16}
                      onClick={() => {
                        if (itemDetails.booking.type === "merchant_appointment") {
                          setShowConfirmAppointmentModal(true);
                        } else {
                          navigate(routes.FITNESSCLASSSESSIONBOOKINGREQUESTSUMMARY, {
                            state: { item: itemDetails },
                          });
                        }
                      }}
                    >
                      {isPackageRedeemableOnly ? "Redeem" : "Pay"}
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
              {itemDetails.booking.status !== "merchant_request" && !itemDetails.nonRefundable && (
                <Grid item paddingTop="30px" paddingBottom={isMobile ? "30px" : "0px"}>
                  <Grid item xs={12}>
                    <Typography fontSize={theme.fonts.fontSizes.size14} textAlign="center">
                      Your booking can&apos;t be edited or cancelled after {itemDetails.editableBy}
                    </Typography>
                  </Grid>
                  <Spacer size="m" />
                  <Grid item xs={12}>
                    <CustomButton
                      disabled={!itemDetails.editable}
                      isLoading={cancelBookingSlotObj.status === "pending"}
                      fontSize={theme.fonts.fontSizes.size16}
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      Cancel Booking
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </ModalBox>
    </Modal>
  );
}

BookingDetailsModal.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    booking: PropTypes.shape({
      status: "",
      type: "",
      date: "",
      time: "",
      startAt: "",
      endAt: "",
      price: "",
      paymentMethod: "",
      package: PropTypes.shape({
        code: "",
      }),
      paymentTransaction: PropTypes.shape({
        method: "",
        paidAt: "",
        subtotal: "",
        membershipDiscount: "",
        voucherDiscount: "",
        totalPrice: "",
      }),

      session: PropTypes.shape({
        questionResponses: PropTypes.arrayOf(
          PropTypes.shape({
            id: 0,
            question: "",
            answer: "",
            response: "",
          }),
        ),
        sessionCategories: PropTypes.arrayOf(
          PropTypes.shape({
            id: 0,
            label: "",
            price: "",
            date: "",
            time: "",
          }),
        ),
        title: "",
        category: "",
        durationMins: 0,
        startTime: "",
        class: PropTypes.shape({
          name: "",
          category: PropTypes.shape({
            label: "",
          }),
        }),
        event: PropTypes.shape({
          title: "",
        }),
        staff: PropTypes.shape({
          id: null,
          firstName: "",
          lastName: "",
        }),
      }),
      location: PropTypes.shape({
        name: "",
        requireVerification: "",
        courts: PropTypes.arrayOf(
          PropTypes.shape({
            id: 0,
            label: "",
          }),
        ),
      }),
    }),
    editable: false,
    editableBy: "",
    nonRefundable: false,
  }),
};

BookingDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  cancelMerchantRequest: PropTypes.func.isRequired,
  rejectAppointmentRequest: PropTypes.func.isRequired,
  acceptRequestedAppointment: PropTypes.func.isRequired,
  setShowEditSessionModal: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    booking: PropTypes.shape({
      status: PropTypes.string,
      type: PropTypes.string,
      publicId: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      startAt: PropTypes.string,
      endAt: PropTypes.string,
      price: PropTypes.string,
      paymentMethod: PropTypes.string,
      package: PropTypes.shape({
        code: PropTypes.string,
      }),
      paymentTransaction: PropTypes.shape({
        method: PropTypes.string,
        paidAt: PropTypes.string,
        subtotal: PropTypes.string,
        membershipDiscount: PropTypes.string,
        voucherDiscount: PropTypes.string,
        totalPrice: PropTypes.string,
      }),
      session: PropTypes.shape({
        requestedPackage: PropTypes.shape({
          id: PropTypes.number,
          code: PropTypes.string,
        }),
        questionResponses: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            question: PropTypes.string,
            answer: PropTypes.string,
            response: PropTypes.string,
          }),
        ),
        sessionCategories: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            price: PropTypes.string,
            date: PropTypes.string,
            time: PropTypes.string,
          }),
        ),
        title: PropTypes.string,
        category: PropTypes.string,
        durationMins: PropTypes.number,
        startTime: PropTypes.string,
        class: PropTypes.shape({
          name: PropTypes.string,
          category: PropTypes.shape({
            label: PropTypes.string,
          }),
        }),
        event: PropTypes.shape({
          title: PropTypes.string,
        }),
        staff: PropTypes.shape({
          id: PropTypes.number,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
      location: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        long: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        requireVerification: PropTypes.bool,
        courts: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        ),
      }),
    }),
    editable: PropTypes.bool,
    editableBy: PropTypes.string,
    nonRefundable: PropTypes.bool,
  }),
};

export default BookingDetailsModal;
