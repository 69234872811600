import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Icon,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import FormPickerDate from "../../../../components/forms/form-picker-date.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../components/utils/skeleton.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import {
  getAvailableHourlyBooking,
  hourlyBookingSelector,
} from "../../../../services/hourly-booking/hourly-booking-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
  overflowY: isMobile && "auto",
}));

const availableSessionValidationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
});

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  border: "1px solid",
  borderColor: theme.palette.colors.ui.border,
  borderRadius: `${theme.shape.borderRadius[1]}px`,
  color: theme.palette.colors.text.primary,
}));

function AvailableHourlyBookingModal({ itemDetails, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { getAvailableHourlyBookingObj } = useSelector(hourlyBookingSelector);
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const navigate = useNavigate();
  const [slots, setSlots] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(
    itemDetails.businesses.length > 1 ? null : itemDetails.businesses[0].id,
  );

  const getSlots = (values) => {
    dispatch(getAvailableHourlyBooking({ ...values, businessId: selectedBusiness })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleSelectSlot = (slot) => {
    if (slots.some((item) => item.startAt === slot.startAt)) {
      setSlots(slots.filter((item) => item.startAt !== slot.startAt));
    } else {
      setSlots([...slots, { startAt: slot.startAt, endAt: slot.endAt }]);
    }
  };

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 24; i += 1) {
      loaders.push(
        <Grid item xs={4} key={i} position="relative">
          <StyledButton disabled>
            <CustomSkeleton width="100%" />
          </StyledButton>
        </Grid>,
      );
    }
    return loaders;
  };

  useEffect(() => {
    if (selectedBusiness) {
      getSlots({ date: format(new Date(), "yyyy-MM-dd") });
      if (formRef && formRef.current) {
        formRef.current.setFieldValue("businessId", selectedBusiness);
      }
    }
  }, [selectedBusiness]);

  const renderAvailableSession = () => {
    if (getAvailableHourlyBookingObj.status === "succeeded") {
      if (getAvailableHourlyBookingObj.data.length > 0) {
        return (
          <>
            <Grid container item justifyContent="center">
              {getAvailableHourlyBookingObj.data[0].businessOffDay ? (
                <>
                  <Grid item xs={12}>
                    <Typography textAlign="center">Selected date is unavailable. </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign="center">Please select another date.</Typography>
                  </Grid>
                </>
              ) : (
                <Spacer />
              )}
            </Grid>

            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {getAvailableHourlyBookingObj.data.map((item) => (
                <Grid item xs={4} key={item.startAt} position="relative">
                  <StyledButton
                    onClick={() => handleSelectSlot(item)}
                    disabled={!item.available || item.booked}
                  >
                    <Typography>{format(new Date(item.startAt), "hh:00a")}</Typography>
                    {(!item.available || item.booked) && (
                      <Icon
                        sx={{
                          height: "100%",
                          position: "absolute",
                          margin: "auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <DoDisturbAltIcon sx={{ color: theme.palette.colors.text.secondary }} />
                      </Icon>
                    )}
                  </StyledButton>
                  {slots.some((selected) => selected.startAt === item.startAt) && (
                    <Icon
                      sx={{
                        position: "absolute",
                        right: -5,
                        top: 0,
                        backgroundColor: theme.palette.colors.bg.white,
                        borderRadius: "50%",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ color: theme.palette.colors.text.success }} />
                    </Icon>
                  )}
                </Grid>
              ))}
            </Grid>
            {!getAvailableHourlyBookingObj.data[0].businessOffDay && (
              <Grid container item justifyContent="center">
                <Grid item xs={6}>
                  {isMobile && <Spacer size="l" />}
                  <CustomButton
                    disabled={slots.length === 0}
                    fontSize={theme.fonts.fontSizes.size18}
                    onClick={() =>
                      navigate(routes.PACKAGE_HOURLY_BOOKING_SUMMARY, {
                        state: { ...formRef.current.values, slots },
                      })
                    }
                  >
                    Book
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </>
        );
      }
      return (
        <Grid container item justifyContent="center" alignContent="center" height="400px">
          <Typography>No available sessions.</Typography>
        </Grid>
      );
    }

    return (
      <Grid container item columnSpacing={1} rowSpacing={1.5} marginTop="10px">
        {renderLoader()}
      </Grid>
    );
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setSelectedBusiness(null);
        setShowModal(false);
      }}
    >
      <ModalBox isMobile={isMobile}>
        <Form
          innerRef={formRef}
          initialValues={{
            date: format(new Date(), "yyyy-MM-dd"),
            businessId: selectedBusiness,
            slots: [],
            packageCode: itemDetails.code,
          }}
          validationSchema={availableSessionValidationSchema}
          onSubmit={getSlots}
        >
          {itemDetails && selectedBusiness ? (
            <PaddedView multiples={2}>
              <TopTitleBox
                title="Available Session"
                requireCloseButton={true}
                setShowModal={setShowModal}
              />
              <Spacer position="top" size="l" />
              <Grid container item xs={12} justifyContent="center" rowSpacing={1.5} padding="10px">
                <Grid item xs={8}>
                  <FormPickerDate name="date" submitOnChange={true} />
                </Grid>
                {renderAvailableSession()}
              </Grid>
            </PaddedView>
          ) : (
            <PaddedView multiples={2}>
              <TopTitleBox
                title={itemDetails.plan.title}
                requireCloseButton={true}
                setShowModal={setShowModal}
              />
              <Spacer position="top" size="l" />
              <Grid container item xs={12} justifyContent="center" rowSpacing={1} padding="10px">
                {itemDetails.businesses.map((item) => (
                  <Grid item xs={12} key={item.id}>
                    <ButtonBase
                      sx={{
                        border: "1px solid",
                        borderColor: theme.palette.colors.brand.primary,
                        borderRadius: `${theme.shape.borderRadius[1]}px`,
                        width: "100%",
                        height: "50px",
                      }}
                      onClick={() => setSelectedBusiness(item.id)}
                    >
                      <Typography color="primary">{item.name}</Typography>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
              <Spacer size="l" />
            </PaddedView>
          )}
        </Form>
      </ModalBox>
    </Modal>
  );
}

AvailableHourlyBookingModal.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    code: "",
    plan: PropTypes.shape({
      title: "",
      imagePath: "",
    }),
    sessions: PropTypes.shape({
      claimedCount: 0,
      noOfSessions: 0,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        name: "",
      }),
    ),
    category: PropTypes.shape({
      type: "",
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: null,
          label: "",
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: "",
      paidAt: "",
      total: "",
    }),
  }),
};

AvailableHourlyBookingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }),
};

export default AvailableHourlyBookingModal;
